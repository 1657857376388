import { DndContext, DragEndEvent, closestCenter } from "@dnd-kit/core";
import {
  SortableContext,
  useSortable,
  verticalListSortingStrategy,
} from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";
import AddIcon from "@mui/icons-material/AddCircleOutline";
import DeleteIcon from "@mui/icons-material/DeleteOutlineOutlined";
import DragIcon from "@mui/icons-material/DragHandle";
import FormatBoldIcon from "@mui/icons-material/FormatBold";
import FormatItalicIcon from "@mui/icons-material/FormatItalic";
import FormatListBulletedIcon from "@mui/icons-material/FormatListBulleted";
import FormatListNumberedIcon from "@mui/icons-material/FormatListNumbered";
import StrikethroughSIcon from "@mui/icons-material/StrikethroughS";
import SubjectIcon from "@mui/icons-material/Subject";
import TextFieldsIcon from "@mui/icons-material/TextFields";
import TitleIcon from "@mui/icons-material/Title";
import {
  Box,
  Paper,
  SxProps,
  Typography,
  alpha,
  useTheme,
} from "@mui/material";
import IconButton from "@mui/material/IconButton";
import { EditorContent, useEditor } from "@tiptap/react";
import { StarterKit } from "@tiptap/starter-kit";
import React, { CSSProperties, useEffect, useRef, useState } from "react";
import { useDeleteNoteTable } from "../../hook";
import {
  useDeleteBlockIndex,
  useInsertBlock,
  useNoteEdit,
  useSelectedNote,
  useSwapNoteBlocks,
} from "../../hook/note";
import {
  useRunTable,
  useTable,
  useTableRunResponse,
  useTableSpec,
} from "../../hook/table";
import {
  NoteBlock,
  PageBlock,
  TableBlock,
  isNoteBlock,
  isTableBlock,
} from "../../types/note";
import { NoteBlockContainer, NotePageContainer } from "../note";
import { SearchTable } from "../searchTable";

export const TableBlockComponent: React.FC<{
  block: TableBlock;
  hovering: boolean;
}> = ({ block }) => {
  const tableId = block["table-id"];
  const table = useTable(tableId);
  const tableSpec = useTableSpec(tableId);
  const name = tableSpec?.name ?? table?.name;
  const description = tableSpec?.description;
  const deleteNoteTable = useDeleteNoteTable();
  const runTable = useRunTable();
  const tableRunResponse = useTableRunResponse(tableId);
  const tableStats = tableRunResponse?.["write-table-rows"]?.stats;

  return (
    <Box id={tableId}>
      <NoteBlockContainer
        name={name ?? ""}
        description={description}
        onDelete={() => {
          deleteNoteTable({ tableId });
        }}
        onRun={() => {
          runTable({ tableId });
        }}
      >
        {tableRunResponse && (
          <>
            <Paper sx={{ padding: 2 }} elevation={7}>
              <Typography variant="subtitle1">Execution Result</Typography>
              {tableRunResponse["no-results"] && (
                <Typography>No input changes. Table didn't execute.</Typography>
              )}
              {tableStats?.insert !== undefined && (
                <Typography>{tableStats.insert} Rows inserted.</Typography>
              )}
              {tableStats?.update !== undefined && (
                <Typography>{tableStats.update} Rows updated.</Typography>
              )}
              {tableStats?.delete !== undefined && (
                <Typography>{tableStats.delete} Rows deleted.</Typography>
              )}
            </Paper>
          </>
        )}
        <SearchTable tableId={tableId} />
      </NoteBlockContainer>
    </Box>
  );
};

export const NoteBlockComponent: React.FC<{
  block: NoteBlock;
  hovering: boolean;
  blockIndex: number;
}> = ({ block, hovering, blockIndex }) => {
  const [noteEdit, setNoteEdit] = useNoteEdit(blockIndex);
  const [selectedButtons, setSelectedButtons] = useState<Set<string>>(
    new Set([])
  );

  const theme = useTheme();
  const primaryColor = theme.palette.primary.main;
  const [inFocus, setInFocus] = useState(false);
  const buttonColor = (buttonKey: string) =>
    selectedButtons.has(buttonKey) ? "primary" : "default";
  const toolbarRef = useRef<HTMLDivElement | undefined>(undefined);
  const styles: CSSProperties = inFocus
    ? {
        borderColor: alpha(primaryColor, 0.75),
        borderStyle: "solid",
        borderWidth: "2px",
      }
    : hovering
    ? {
        borderColor: alpha(primaryColor, 0.25),
        borderStyle: "solid",
        borderWidth: "1px",
        margin: "1px",
      }
    : {
        margin: "2px",
      };

  const editor = useEditor({
    extensions: [StarterKit],
    content: block.html,
    onUpdate: ({ editor }) => {
      const html = editor.getHTML();
      setNoteEdit(html);
    },
  });

  useEffect(() => {
    if (!editor) return;
    editor.commands.setContent(block.html);
  }, [block.html, editor]);

  useEffect(() => {
    if (!editor) return;

    const updateSelectedButtons = () => {
      const bold = editor.isActive("bold") ? ["bold"] : [];
      const italic = editor.isActive("italic") ? ["italic"] : [];
      const orderedList = editor.isActive("orderedList") ? ["orderedList"] : [];
      const bulletList = editor.isActive("bulletList") ? ["bulletList"] : [];
      const heading1 = editor.isActive("heading", { level: 1 })
        ? ["heading1"]
        : [];
      const heading2 = editor.isActive("heading", { level: 2 })
        ? ["heading2"]
        : [];
      const paragraph = editor.isActive("paragraph") ? ["paragraph"] : [];
      setSelectedButtons(
        new Set([
          ...bold,
          ...italic,
          ...orderedList,
          ...bulletList,
          ...heading1,
          ...heading2,
          ...paragraph,
        ])
      );
    };
    const handleFocus = () => {
      setInFocus(true);
    };
    const handleBlur = (e: { event: FocusEvent }) => {
      const event = e.event;
      if (toolbarRef.current?.contains(event.relatedTarget as Node)) return;
      setInFocus(false);
    };

    editor.on("transaction", updateSelectedButtons);
    editor.on("focus", handleFocus);
    editor.on("blur", handleBlur);

    return () => {
      editor.off("transaction", updateSelectedButtons);
      editor.off("focus", handleFocus);
      editor.off("blur", handleBlur);
    };
  }, [editor]);

  return (
    <Box sx={{ display: "flex", flexDirection: "row", flexGrow: 1 }}>
      {editor && (
        <Box sx={{ display: "flex", flexDirecion: "row", flexGrow: 1 }}>
          <Box
            ref={toolbarRef}
            sx={{ width: 0, height: 0, position: "relative" }}
          >
            <Paper
              elevation={9}
              sx={{
                position: "absolute",
                flexDirection: "column",
                display: "flex",
                visibility: inFocus ? "visible" : "hidden",
                transform: "translateX(calc(-100% - 0.25rem))",
              }}
            >
              <IconButton
                color={buttonColor("bold")}
                onClick={() => editor.chain().focus().toggleBold().run()}
              >
                <FormatBoldIcon />
              </IconButton>
              <IconButton
                color={buttonColor("italic")}
                onClick={() => editor.chain().focus().toggleItalic().run()}
              >
                <FormatItalicIcon />
              </IconButton>
              <IconButton
                onClick={() => editor.chain().focus().toggleStrike().run()}
              >
                <StrikethroughSIcon />
              </IconButton>
              <IconButton
                color={buttonColor("paragraph")}
                onClick={() => editor.chain().focus().setParagraph().run()}
              >
                <SubjectIcon />
              </IconButton>
              <IconButton
                color={buttonColor("heading1")}
                onClick={() =>
                  editor.chain().focus().toggleHeading({ level: 1 }).run()
                }
              >
                <TitleIcon />
              </IconButton>
              <IconButton
                color={buttonColor("heading2")}
                onClick={() =>
                  editor.chain().focus().toggleHeading({ level: 2 }).run()
                }
              >
                <TextFieldsIcon />
              </IconButton>
              <IconButton
                color={buttonColor("bulletList")}
                onClick={() => editor.chain().focus().toggleBulletList().run()}
              >
                <FormatListBulletedIcon />
              </IconButton>
              <IconButton
                color={buttonColor("orderedList")}
                onClick={() => editor.chain().focus().toggleOrderedList().run()}
              >
                <FormatListNumberedIcon />
              </IconButton>
            </Paper>
          </Box>
          <Paper
            onClick={() => {
              editor.chain().focus();
            }}
            sx={{
              display: "flex",
              flexDirection: "row",
              flexGrow: 1,
            }}
          >
            <EditorContent
              style={{
                display: "flex",
                flexDirection: "column",
                width: "100%",
                paddingLeft: "2rem",
                paddingRight: "2rem",
                ...styles,
              }}
              editor={editor}
            ></EditorContent>
          </Paper>
        </Box>
      )}
    </Box>
  );
};

export const AddBlockButton: React.FC<{
  child: (props: { hovering: boolean }) => JSX.Element;
  blockIndex: number;
  canDelete?: boolean;
}> = ({ child, blockIndex, canDelete = false }) => {
  const insertBlock = useInsertBlock();
  const deleteBlockIndex = useDeleteBlockIndex();
  const [hovering, setHovering] = useState(false);
  const onMouseEnter = () => setHovering(true);
  const onMouseLeave = () => setHovering(false);
  const buttonContainerProps: SxProps = {
    display: "flex",
    flexBasis: 1,
    flexGrow: 1,
    alignItems: "center",
    justifyContent: "center",
  };
  const iconProps: SxProps = {
    visibility: hovering ? "visible" : "hidden",
    opacity: "0.25",
    "&:hover": {
      opacity: "0.75",
    },
  };
  const { attributes, listeners, setNodeRef, transform, transition } =
    useSortable({ id: blockIndex });

  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
  };

  return (
    <Box
      {...attributes}
      ref={setNodeRef}
      sx={{
        ...style,
        transform: transform
          ? `translate3d(${transform.x}px, ${transform.y}px, 0) scale(1.05)`
          : undefined,
        transition: transition || "transform 0.3s ease-out",
      }}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
    >
      {child({ hovering })}
      <Box sx={{ display: "flex" }}>
        <Box sx={{ ...buttonContainerProps, justifyContent: "start" }}>
          <IconButton {...listeners} sx={{ ...iconProps }}>
            <DragIcon />
          </IconButton>
        </Box>
        <Box sx={{ ...buttonContainerProps }}>
          <IconButton
            onClick={() => insertBlock({ blockIndex })}
            sx={{ ...iconProps }}
          >
            <AddIcon />
          </IconButton>
        </Box>
        <Box sx={{ ...buttonContainerProps, justifyContent: "end" }}>
          {canDelete && (
            <IconButton
              onClick={() => deleteBlockIndex({ blockIndex })}
              sx={{ ...iconProps }}
            >
              <DeleteIcon />
            </IconButton>
          )}
        </Box>
      </Box>
    </Box>
  );
};

export const PageBlockComponent: React.FC<{
  block: PageBlock;
  blockIndex: number;
}> = ({ block, blockIndex }) => {
  const blockElement = isNoteBlock(block)
    ? (hovering: boolean) => (
        <NoteBlockComponent
          block={block}
          hovering={hovering}
          blockIndex={blockIndex}
        />
      )
    : isTableBlock(block)
    ? (hovering: boolean) => (
        <TableBlockComponent block={block} hovering={hovering} />
      )
    : "error";
  const canDelete = isNoteBlock(block);

  if (blockElement === "error") {
    throw new Error("Unrecognized page block.");
  }

  const childProp = ({ hovering }: { hovering: boolean }) =>
    blockElement(hovering);

  return (
    <AddBlockButton
      child={childProp}
      blockIndex={blockIndex}
      canDelete={canDelete}
    />
  );
};

export const NotePage: React.FC = () => {
  const [selectedNote, setSelectedNote] = useSelectedNote();
  const blocks = selectedNote?.["page-data"]?.blocks ?? [];
  // const blocks = [{ html: "<p>add stuff</p>" }];
  const pageName = selectedNote?.["page-data"].name;
  const swapNoteBlocks = useSwapNoteBlocks();
  const children = blocks.map((block, i) => {
    return <PageBlockComponent key={i} block={block} blockIndex={i} />;
  });
  const addBlock =
    blocks.length === 0 ? (
      <AddBlockButton
        blockIndex={0}
        child={({ hovering }) => (
          <Box sx={{ display: "flex", flexGrow: 1, justifyContent: "center" }}>
            <Typography>Notebook is empty</Typography>
          </Box>
        )}
      />
    ) : (
      <></>
    );

  const onDragEnd = (e: DragEndEvent) => {
    const firstIndex = e.over?.id as number;
    const secondIndex = e.active?.id as number;
    if (firstIndex === undefined) {
      return;
    }
    if (secondIndex === undefined) {
      return;
    }
    swapNoteBlocks({ firstIndex, secondIndex });
    // console.log(e);
  };

  return (
    <NotePageContainer pageName={pageName!}>
      <DndContext collisionDetection={closestCenter} onDragEnd={onDragEnd}>
        <SortableContext
          items={blocks.map((x, i) => i)}
          strategy={verticalListSortingStrategy}
        >
          {children}
        </SortableContext>
      </DndContext>
      {addBlock}
    </NotePageContainer>
  );
};
