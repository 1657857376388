import * as R from "ramda";
import { useDispatch, useSelector } from "react-redux";
import {
  getNotebook,
  getTable,
  getTableLogs,
  getTableRunHistory,
  getTableSpec,
  runTable,
  setTableCode,
  setTableTrigger,
} from "../api";
import { useAppId } from "../hook";
import {
  ActionType,
  AddTablePageConfig,
  AppDispatch,
  RootState,
} from "../reducer";
import {
  GetTableRunHistoryResponse,
  Table,
  TableLogsMap,
  TableNotFound,
  TableNotFoundMap,
  TableOrNotFound,
  TableRunHistoryMap,
  TableRunResponses,
  TableRunSuccess,
  TableSpec,
  TableSpecMap,
  TableTrigger,
  TableType,
} from "../types/table";
import { useNotebook } from "./note";

export const useTableRunResponses: () => [
  TableRunResponses,
  (tableRunResponses: TableRunResponses) => void
] = () => {
  const tableRunResponses = useSelector(
    (state: RootState) => state.main.tableRunResponses
  );
  const dispatch = useDispatch<AppDispatch>();
  const setTableRunResponses = (tableRunResponses: TableRunResponses) =>
    dispatch({ type: ActionType.SET_TABLE_RUN_RESPONSES, tableRunResponses });
  return [tableRunResponses, setTableRunResponses];
};

export const useTableSpecs: () => [
  TableSpecMap,
  (tableSpecs: TableSpecMap) => void
] = () => {
  const tableSpecs = useSelector((state: RootState) => state.main.tableSpecs);
  const dispatch = useDispatch<AppDispatch>();
  const setTableSpecs = (tableSpecs: TableSpecMap) =>
    dispatch({ type: ActionType.SET_TABLE_SPECS, tableSpecs });
  return [tableSpecs, setTableSpecs];
};

export const useTableRunResponse: (
  tableId: string
) => TableRunSuccess | undefined = (tableId) => {
  const [tableRunResponses, setTableRunResponses] = useTableRunResponses();
  return tableRunResponses[tableId];
};

export const useFetchTableSpecs: () => (config: {
  tableIds: string[];
}) => Promise<TableSpecMap> = () => {
  const [appIdMaybe] = useAppId();
  const appId = appIdMaybe!;
  const [tableSpecs, setTableSpecs] = useTableSpecs();

  const fetchTableSpecs: (config: {
    tableIds: string[];
    appId: string;
  }) => Promise<TableSpec[]> = ({ appId, tableIds }) => {
    return Promise.all(
      R.map((tableId: string) => getTableSpec({ appId, tableId }), tableIds)
    );
  };

  return async ({ tableIds }) => {
    const tableSpecsResponse = await fetchTableSpecs({
      tableIds,
      appId: appId!,
    });
    const addedTableSpecs: TableSpecMap = Object.fromEntries(
      R.map(
        (tableSpec) => [tableSpec["table-id"], tableSpec],
        tableSpecsResponse
      )
    );
    const newTableSpecs = { ...tableSpecs, ...addedTableSpecs };
    setTableSpecs(newTableSpecs);
    return addedTableSpecs;
  };
};

export const useAddNotFoundTables: () => (
  notFoundTables: TableNotFound[]
) => void = () => {
  const dispatch = useDispatch();
  const addNotFoundTables = (notFoundTables: TableNotFound[]) => {
    dispatch({ type: ActionType.ADD_NOT_FOUND_TABLES, notFoundTables });
  };
  return addNotFoundTables;
};

export const useAddTables: () => (tables: Table[]) => void = () => {
  const dispatch = useDispatch();
  const addTablePages = useAddTablePages();
  const addTables = (tables: Table[]) => {
    dispatch({ type: ActionType.ADD_TABLES, tables });
    tables.forEach((table) => {
      if (table.rows) {
        addTablePages({
          tableId: table["table-id"],
          index: 0,
          rows: table.rows,
        });
      }
    });
  };
  return addTables;
};

export const useAddTablePages: () => (
  config: AddTablePageConfig
) => void = () => {
  const dispatch = useDispatch();
  const addTablePages = (config: AddTablePageConfig) => {
    dispatch({ type: ActionType.ADD_TABLE_PAGE, ...config });
  };
  return addTablePages;
};

export const useNotFoundTables: () => TableNotFoundMap = () => {
  const notFoundTables = useSelector(
    (state: RootState) => state.main.notFoundTables
  );
  return notFoundTables;
};

export const useIsTableNotFound: () => (tableId?: string) => boolean = () => {
  const notFoundTables = useNotFoundTables();

  return (tableId) => {
    return tableId !== undefined && tableId in notFoundTables;
  };
};

export const useFetchTables: () => (config: {
  tableSpecs: TableSpecMap;
}) => Promise<Table[]> = () => {
  const [appIdMaybe] = useAppId();
  const appId = appIdMaybe!;
  const [tableSpecs, setTableSpecs] = useTableSpecs();
  const addTables = useAddTables();
  const addNotFoundTables = useAddNotFoundTables();

  const fetchTables: (config: {
    tableIds: string[];
  }) => Promise<TableOrNotFound[]> = ({ tableIds }) => {
    return Promise.all(
      R.map(
        (tableId: string) =>
          getTable({ appId, tableId }).catch((e) => {
            return {
              "not-found": true,
              "table-id": tableId,
            } as TableNotFound;
          }),
        tableIds
      )
    );
  };

  return async ({ tableSpecs }) => {
    const nonErrorTableIds = Object.values(tableSpecs)
      .filter((tableSpec) => !tableSpec["execute-error"])
      .map((tableSpec) => tableSpec["table-id"]);
    const tableOrNotFounds = await fetchTables({ tableIds: nonErrorTableIds });
    const tables = tableOrNotFounds
      .filter((t) => "file-id" in t)
      .map((t) => t as Table);
    const notFoundTables = tableOrNotFounds
      .filter((t) => "not-found" in t)
      .map((t) => t as TableNotFound);
    addTables(tables);
    addNotFoundTables(notFoundTables);
    return tables;
  };
};

export const useRunTable: () => (config: { tableId: string }) => void = () => {
  const [tableRunResponses, setTableRunResponses] = useTableRunResponses();
  const [appId] = useAppId();
  const [notebook, setNotebook] = useNotebook();
  const fetchTableLogs = useFetchTableLogs();
  const fetchTableSpecs = useFetchTableSpecs();
  const fetchTableRunHistory = useFetchTableRunHistory();

  return ({ tableId }) => {
    runTable({ tableId, appId: appId! }).then((res) => {
      const newTableRunResponses = { ...tableRunResponses, [tableId]: res };
      setTableRunResponses(newTableRunResponses);
      fetchTableSpecs({ tableIds: [tableId] });
      fetchTableLogs(tableId);
      getNotebook({ appId: appId! }).then((notebook) => {
        setNotebook(notebook);
      });
      fetchTableRunHistory(tableId);
    });
  };
};

export const useSetTableTrigger: () => (config: {
  tableId: string;
  trigger: TableTrigger;
}) => void = () => {
  const [appId] = useAppId();
  const [notebook, setNotebook] = useNotebook();

  return ({ tableId, trigger }) => {
    setTableTrigger({ tableId, trigger }).then((res) => {
      getNotebook({ appId: appId! }).then((notebook) => {
        setNotebook(notebook);
      });
    });
  };
};

export const useSetTableCode: () => (config: {
  tableId: string;
  code: string;
}) => Promise<void> = () => {
  const [appId] = useAppId();
  const [notebook, setNotebook] = useNotebook();
  const runTable = useRunTable();

  return ({ tableId, code }) => {
    return setTableCode({ tableId, code }).then((res) => {
      runTable({ tableId });
      return getNotebook({ appId: appId! }).then((notebook) => {
        setNotebook(notebook);
      });
    });
  };
};

export const useTableLogsMap: () => [
  TableLogsMap,
  (tableRunResponses: TableLogsMap) => void
] = () => {
  const tableLogsMap = useSelector(
    (state: RootState) => state.main.tableLogsMap
  );
  const dispatch = useDispatch<AppDispatch>();
  const setTableLogsMap = (tableLogsMap: TableLogsMap) =>
    dispatch({ type: ActionType.SET_TABLE_LOGS_MAP, tableLogsMap });
  return [tableLogsMap, setTableLogsMap];
};

export const useSetTableLogs: () => (config: {
  tableId: string;
  logs: string;
}) => void = () => {
  const [tableLogsMap, setTableLogsMap] = useTableLogsMap();
  return ({ tableId, logs }) => {
    const newTableLogsMap = { ...tableLogsMap, [tableId]: logs };
    setTableLogsMap(newTableLogsMap);
  };
};

export const useGetTableLogsForTable: () => (
  tableId?: string
) => string = () => {
  const [tableLogsMap, setTableLogsMap] = useTableLogsMap();

  return (tableId) => {
    return tableId ? tableLogsMap[tableId] || "" : "";
  };
};

export const useFetchTableLogs: () => (
  tableId: string
) => Promise<string> = () => {
  const setTableLogs = useSetTableLogs();
  const [appId, setAppId] = useAppId();

  return (tableId) => {
    return getTableLogs({ tableId, appId: appId! }).then((res) => {
      setTableLogs({ tableId, logs: res.logs });
      return res.logs;
    });
  };
};

export const useTables = () => {
  const tables = useSelector((state: RootState) => state.main.tables);
  return tables;
};

export const useTable: (tableId?: string) => Table | undefined = (tableId) => {
  const table = useSelector((state: RootState) =>
    tableId ? state.main.tables?.[tableId] : undefined
  );
  return table;
};

export const useTableInputs: (tableId?: string) => Table[] = (tableId) => {
  const tableSpec = useTableSpec(tableId);
  const inputFileIds = tableSpec?.inputs ?? [];
  const tables = useSelector((state: RootState) => state.main.tables);
  const fileIdToTable = Object.fromEntries(
    Object.values(tables).map((table) => [table["file-id"], table])
  );
  const inputTables = inputFileIds
    .map((fileId) => fileIdToTable?.[fileId])
    .filter((x) => x);
  return inputTables;
};

export const useTableSpec: (tableId?: string) => TableSpec | undefined = (
  tableId
) => {
  const tableSpec = useSelector((state: RootState) =>
    tableId ? state.main.tableSpecs[tableId] : undefined
  );
  return tableSpec;
};

export const useTableType: (tableId?: string) => TableType = (tableId) => {
  const tableSpec = useTableSpec(tableId);
  const table = useTable(tableId);
  if (tableSpec) {
    return TableType.CODE_TABLE;
  }
  if (table) {
    return TableType.DATA_TABLE;
  }
  return TableType.LOADING;
};

export const useTableRunHistoryMap: () => [
  TableRunHistoryMap,
  (TableRunHistoryMap: TableRunHistoryMap) => void
] = () => {
  const tableRunHistoryMap = useSelector(
    (state: RootState) => state.main.tableRunHistoryMap
  );
  const dispatch = useDispatch<AppDispatch>();
  const setTableLogsMap = (tableRunHistoryMap: TableRunHistoryMap) =>
    dispatch({
      type: ActionType.SET_TABLE_RUN_HISTORY_MAP,
      tableRunHistoryMap,
    });
  return [tableRunHistoryMap, setTableLogsMap];
};

export const useFetchTableRunHistory: () => (
  tableId: string
) => Promise<any> = () => {
  const [tableRunHistoryMap, setTableRunHistoryMap] = useTableRunHistoryMap();
  const [appId, setAppId] = useAppId();

  return async (tableId: string) => {
    getTableRunHistory({ appId: appId!, tableId }).then((response) => {
      setTableRunHistoryMap({ ...tableRunHistoryMap, [tableId]: response });
      return response;
    });
  };
};

export const useTableRunHistory: (
  tableId?: string
) => GetTableRunHistoryResponse | undefined = (tableId) => {
  const [tableRunHistoryMap, setTableRunHistoryMap] = useTableRunHistoryMap();

  return tableId ? tableRunHistoryMap[tableId] : undefined;
};
