import { Link, Paper } from "@mui/material";
import { useSelectedConnectCard } from "../../hook/pullConnector";

export const AddConnectCardPage: React.FC = () => {
  const [selectedConnectCard, setSelectedConnectCard] =
    useSelectedConnectCard();

  return (
    <Paper sx={{ padding: 3 }}>
      {selectedConnectCard ? (
        <Link href={selectedConnectCard?.url}>
          Configure Connector For {selectedConnectCard?.service}
        </Link>
      ) : (
        <>Loading...</>
      )}
    </Paper>
  );
};
