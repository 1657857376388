import { useSelectedPage } from "../../../hook";
import { useTable } from "../../../hook/table";
import { NoteBlockContainer, NotePageContainer } from "../../note";
import { SearchTable } from "../../searchTable";

export const PullConnectorTablePage: React.FC = () => {
  const [selectedPage, setSelectedNote] = useSelectedPage();
  const page = selectedPage.page;
  const tableId = page.tableId;
  const table = useTable(tableId)!;
  const tableName = table.name;
  return (
    <NotePageContainer pageName={tableName}>
      <NoteBlockContainer name={tableName}>
        <SearchTable tableId={tableId} />
      </NoteBlockContainer>
    </NotePageContainer>
  );
};
