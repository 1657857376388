import { python } from "@codemirror/lang-python";
import SaveIcon from "@mui/icons-material/Check";
import CancelIcon from "@mui/icons-material/Clear";
import EditIcon from "@mui/icons-material/Edit";
import {
  Box,
  Button,
  CircularProgress,
  FormControl,
  IconButton,
  InputLabel,
  MenuItem,
  Table as MuiTable,
  Paper,
  Select,
  Stack,
  Tab,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Tabs,
  TextField,
  Typography,
  useTheme,
} from "@mui/material";
import CodeMirror from "@uiw/react-codemirror";
import React, { useEffect, useState } from "react";
import { fetchTableCount, fetchTablePage, getTableLogs } from "../api";
import { useAppId } from "../hook";
import { useNavigateTable } from "../hook/pullConnector";
import {
  useFetchTableLogs,
  useFetchTableRunHistory,
  useGetTableLogsForTable,
  useIsTableNotFound,
  useRunTable,
  useSetTableCode,
  useSetTableTrigger,
  useTable,
  useTableInputs,
  useTableRunHistory,
  useTableSpec,
  useTableType,
} from "../hook/table";
import {
  TableRunHistoryElement,
  TableSpec,
  TableType,
  TriggerIndex,
  indexToTrigger,
  tableRunAsError,
  tableRunAsSuccess,
  triggerToIndex,
} from "../types/table";

export const DataTable: React.FC<{ tableId: string }> = ({ tableId }) => {
  const table = useTable(tableId);
  const [index, setIndex] = useState<number>(0);
  const [rows, setRows] = useState<string[][]>([]);
  const header = table?.header ?? [];
  const [isLoading, setIsLoading] = useState(true);
  const page = index;
  const setPage = (page: number) => setIndex(page);
  const [tableCount, setTableCount] = useState<number>(0);
  const [appId, setAppId] = useAppId();
  const theme = useTheme();

  useEffect(() => {
    if (!appId || !tableId) return;

    fetchTableCount({ appId: appId, tableId }).then((res) => {
      setTableCount(res.count);
    });
  }, [tableCount, appId, tableId]);

  useEffect(() => {
    if (!tableId || !appId) return;
    setIsLoading(true);
    fetchTablePage({ appId, index, tableId, pageSize: 10 }).then((res) => {
      setRows(res.rows);
      setIsLoading(false);
    });
  }, [tableId, index]);

  useEffect(() => {
    if (!table?.rows) return;
    setIsLoading(false);
    setRows(table.rows);
  }, [table?.rows?.length]);

  return (
    <Box sx={{ flexDirection: "column" }}>
      <TableContainer component={Paper} sx={{ maxHeight: "40rem" }}>
        <MuiTable>
          <TableHead>
            <TableRow>
              {header?.map((name: any, index: number) => (
                <TableCell sx={{ height: "4rem" }} key={index}>
                  {name}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          {!isLoading && (
            <TableBody>
              {rows?.map((row: any, rowIndex: number) => (
                <TableRow key={rowIndex}>
                  {row.map((cell: any, cellIndex: number) => {
                    return (
                      <TableCell sx={{ height: "4rem" }} key={cellIndex}>
                        {JSON.stringify(cell)}
                      </TableCell>
                    );
                  })}
                </TableRow>
              ))}
            </TableBody>
          )}
        </MuiTable>
      </TableContainer>
      {isLoading && (
        <Box
          sx={{
            height: "40rem",
            width: "100%",
            display: "flex",
            justifyContent: "center",
            padding: "2rem",
          }}
        >
          <CircularProgress />
        </Box>
      )}
      <TablePagination
        component="div"
        onPageChange={(e, value) => setPage(value)}
        rowsPerPage={10}
        rowsPerPageOptions={[10]}
        page={page}
        count={tableCount}
        showFirstButton={true}
        showLastButton={true}
      />
    </Box>
  );
};

export const CodeTabCode: React.FC<{ tableSpec?: TableSpec }> = ({
  tableSpec,
}) => {
  const [editing, setEditing] = useState(false);
  const [code, _setCode] = useState("");
  const setTableCode = useSetTableCode();
  const tableId = tableSpec?.["table-id"]!;
  const runTable = useRunTable();
  const specCode = tableSpec?.code;

  const setCode = (code: string) => {
    const cleanedCode = code.replace(/^```python\s*|\s*```$/g, "");
    _setCode(cleanedCode);
  };

  useEffect(() => {
    setCode(specCode ?? "");
  }, [specCode]);

  const onEdit = () => {
    setEditing(true);
  };

  const onCancel = () => {
    setCode(specCode ?? "");
    setEditing(false);
  };

  const onSave = () => {
    setEditing(false);
    setTableCode({ code, tableId }).then(() => {
      runTable({ tableId });
    });
  };

  return (
    <>
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          alignItems: "start",
          height: "3rem",
        }}
      >
        <Typography variant="subtitle1">Code</Typography>
        <Box>
          {editing && (
            <IconButton color="primary" onClick={onSave}>
              <SaveIcon />
            </IconButton>
          )}
          {editing && (
            <IconButton color="primary" onClick={onCancel}>
              <CancelIcon />
            </IconButton>
          )}
          <IconButton color="primary" onClick={onEdit} disabled={editing}>
            <EditIcon />
          </IconButton>
        </Box>
      </Box>
      <CodeMirror
        editable={editing}
        value={code}
        theme={"dark"}
        height="40rem"
        extensions={[python()]}
        onChange={(value: any) => setCode(value)}
      />
    </>
  );
};

export const CodeTabLogs: React.FC<{ tableSpec?: TableSpec }> = ({
  tableSpec,
}) => {
  const getTableLogsForTable = useGetTableLogsForTable();
  const logs = getTableLogsForTable(tableSpec?.["table-id"]);
  const [logsLoading, setLogsLoading] = useState(true);
  const tableId = tableSpec?.["table-id"];
  const fetchTableLogs = useFetchTableLogs();

  useEffect(() => {
    if (!tableId) return;
    setLogsLoading(true);
    fetchTableLogs(tableId).then(() => {
      setLogsLoading(false);
    });
  }, [tableId]);

  return (
    <>
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          alignItems: "start",
          height: "3rem",
        }}
      >
        <Typography variant="subtitle1">Logs</Typography>
      </Box>
      {logsLoading ? (
        <Box
          sx={{ padding: "1rem", display: "flex", justifyContent: "center" }}
        >
          <CircularProgress />
        </Box>
      ) : (
        <div
          style={{
            backgroundColor: "rgb(29, 31, 33)",
            color: "rgb(197, 200, 198)",
            width: "100%",
            height: "40rem",
            padding: "1rem",
            overflowY: "auto",
            whiteSpace: "pre",
          }}
        >
          {logs ? logs : "No Logs"}
        </div>
      )}
    </>
  );
};

export const CodeTab: React.FC<{ tableSpec?: TableSpec }> = ({ tableSpec }) => {
  const paperStyles = {
    overflowY: "auto",
    display: "flex",
    flexDirection: "column",
    height: "50rem",
    width: "50%",
    padding: 2,
    margin: 1,
  };
  return (
    <Box
      sx={{
        flexDirection: "row",
        display: "flex",
      }}
    >
      <Paper elevation={7} sx={{ ...paperStyles }}>
        <CodeTabCode tableSpec={tableSpec} />
      </Paper>
      <Paper elevation={7} sx={{ ...paperStyles }}>
        <CodeTabLogs tableSpec={tableSpec} />
      </Paper>
    </Box>
  );
};

export const LogsTab: React.FC<{ tableSpec?: TableSpec }> = ({ tableSpec }) => {
  const [logs, setLogs] = useState<string | undefined>();
  const [logsLoading, setLogsLoading] = useState(true);
  const tableId = tableSpec?.["table-id"];
  const [appId, setAppId] = useAppId();

  useEffect(() => {
    if (!tableId) return;
    setLogsLoading(true);
    getTableLogs({ tableId, appId: appId! }).then(({ logs }) => {
      setLogsLoading(false);
      setLogs(logs);
    });
  }, [tableId]);

  return (
    <Box sx={{ overflowY: "auto" }}>
      {logsLoading ? (
        <Box
          sx={{ padding: "1rem", display: "flex", justifyContent: "center" }}
        >
          <CircularProgress />
        </Box>
      ) : (
        <pre
          style={{
            backgroundColor: "rgb(29, 31, 33)",
            color: "rgb(197, 200, 198)",
          }}
        >
          {logs}
        </pre>
      )}
    </Box>
  );
};

export const UpdatesTab: React.FC<{ tableSpec?: TableSpec }> = ({
  tableSpec,
}) => {
  const tableId = tableSpec?.["table-id"];
  const updateInterval = tableSpec?.update_interval;
  const minutes = updateInterval?.minutes;
  const hours = updateInterval?.hours;
  const days = updateInterval?.days;
  const seconds = updateInterval?.seconds;
  const trigger = tableSpec?.trigger!;
  const selectValue = Number(
    trigger ? triggerToIndex[trigger] : TriggerIndex.ALWAYS
  );
  const setTableTrigger = useSetTableTrigger();

  return (
    <Box sx={{ overflowY: "auto" }}>
      <Box
        sx={{
          padding: "1rem",
          gap: 3,
          display: "flex",
          justifyContent: "center",
        }}
      >
        <Paper
          sx={{ padding: "1rem", flexGrow: 1, flexBasis: 1 }}
          elevation={5}
        >
          <Box sx={{ display: "flex", flexDirection: "column", gap: 2 }}>
            <Typography variant="h6">Run Frequency</Typography>
            <Typography variant="body1">
              This is how often this code is executed.
            </Typography>
            <TextField disabled={true} label="Days" value={days ?? 0} />
            <TextField disabled={true} label="Hours" value={hours ?? 0} />
            <TextField disabled={true} label="Minutes" value={minutes ?? 0} />
            <TextField disabled={true} label="Seconds" value={seconds ?? 0} />
          </Box>
        </Paper>
        <Paper
          sx={{ padding: "1rem", flexGrow: 1, flexBasis: 1 }}
          elevation={5}
        >
          <Box sx={{ display: "flex", flexDirection: "column", gap: 2 }}>
            <Typography variant="h6">Trigger</Typography>
            <Typography variant="body1">
              This is what constitutes for an input to this code.
            </Typography>
            <Typography variant="body1">
              Always: Every row in the input table will be included as an input
              to this code.
            </Typography>
            <Typography variant="body1">
              Edit: Only rows that change value from the last time this has run.
              This means another value other than the primary key.
            </Typography>
            <Typography variant="body1">
              Delete: Only rows that are no longer present since the last time
              this has run. This means any row in which the primary key is no
              longer present.
            </Typography>
            <Typography variant="body1">
              Create: Only new rows since the last time this has run. This means
              any row in which a distinct primary key has been added to the
              input since the last time this code was run.
            </Typography>
            <FormControl>
              <InputLabel id="update-trigger-label">Trigger</InputLabel>
              <Select
                label="Trigger"
                labelId="update-trigger-label"
                aria-label="Update Trigger"
                value={selectValue}
                onChange={(e) => {
                  const numVal = Number(e.target.value) as TriggerIndex;
                  const newTrigger = indexToTrigger[numVal];
                  setTableTrigger({ tableId: tableId!, trigger: newTrigger });
                }}
              >
                <MenuItem value={TriggerIndex.ALWAYS}>Always</MenuItem>
                <MenuItem value={TriggerIndex.CREATE}>Create</MenuItem>
                <MenuItem value={TriggerIndex.UPDATE}>Update</MenuItem>
                <MenuItem value={TriggerIndex.DELETE}>Delete</MenuItem>
              </Select>
            </FormControl>
          </Box>
        </Paper>
      </Box>
    </Box>
  );
};

export const InputsTab: React.FC<{ tableSpec?: TableSpec }> = ({
  tableSpec,
}) => {
  const tableId = tableSpec?.["table-id"];
  const tableInputs = useTableInputs(tableId);
  const [appId, setAppId] = useAppId();
  const navigateTable = useNavigateTable();

  return (
    <Box sx={{ overflowY: "auto", flexDirection: "column" }}>
      {tableInputs.map((table, index) => {
        return (
          <Button onClick={() => navigateTable(table["table-id"])} key={index}>
            {table.name}
          </Button>
        );
      })}
    </Box>
  );
};

export const RunHistoryTab: React.FC<{ tableSpec?: TableSpec }> = ({
  tableSpec,
}) => {
  const tableId = tableSpec?.["table-id"];
  const header = [
    "run-id",
    "timestamp",
    "inserts",
    "updates",
    "deletes",
    "Error",
    "Error Line",
    "Error Message",
  ];
  const tableRunHistory = useTableRunHistory(tableId);
  const fetchTableRunHistory = useFetchTableRunHistory();
  const runs = tableRunHistory?.["table-runs"];
  const isLoading = tableRunHistory === undefined;

  useEffect(() => {
    if (!tableId) return;
    if (tableRunHistory) return;
    fetchTableRunHistory(tableId);
  }, [tableId, tableRunHistory]);

  return (
    <Box sx={{ flexDirection: "column" }}>
      <TableContainer component={Paper} sx={{ maxHeight: "40rem" }}>
        <MuiTable>
          <TableHead>
            <TableRow>
              {header?.map((name: any, index: number) => (
                <TableCell sx={{ height: "4rem" }} key={index}>
                  {name}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          {!isLoading && (
            <TableBody>
              {runs?.map(
                (runElement: TableRunHistoryElement, rowIndex: number) => {
                  const run = runElement["table-run"];
                  const time = new Date(runElement.timestamp);
                  const timeFormat = time.toLocaleDateString(undefined, {
                    year: "numeric",
                    month: "2-digit",
                    day: "2-digit",
                    hour: "2-digit",
                    minute: "2-digit",
                    second: "2-digit",
                    hour12: true,
                  });
                  const runId = runElement["run-id"];
                  const runSuccess = tableRunAsSuccess(run);
                  const runError = tableRunAsError(run);
                  const runStats = runSuccess?.["write-table-rows"]?.stats;
                  const inserts = runStats?.insert ?? 0;
                  const updates = runStats?.update ?? 0;
                  const deletes = runStats?.delete ?? 0;
                  const errorLine = runError?.error_line;
                  const errorMessage = runError?.message;
                  const error = runSuccess === undefined ? "true" : "false";

                  return (
                    <TableRow key={rowIndex}>
                      <TableCell>{runId}</TableCell>
                      <TableCell>{timeFormat}</TableCell>
                      <TableCell>{inserts}</TableCell>
                      <TableCell>{updates}</TableCell>
                      <TableCell>{deletes}</TableCell>
                      <TableCell>{error}</TableCell>
                      <TableCell>{errorLine}</TableCell>
                      <TableCell>{errorMessage}</TableCell>
                    </TableRow>
                  );
                }
              )}
            </TableBody>
          )}
        </MuiTable>
      </TableContainer>
      {isLoading && (
        <Box
          sx={{
            height: "40rem",
            width: "100%",
            display: "flex",
            justifyContent: "center",
            padding: "2rem",
          }}
        >
          <CircularProgress />
        </Box>
      )}
    </Box>
  );
};

export const ErrorTab: React.FC<{
  tableSpec: TableSpec;
}> = ({ tableSpec }) => {
  const { error, error_line, error_line_number, message, stack_trace } =
    tableSpec["execute-error"]!;

  return (
    <Box padding={2}>
      <Typography variant="h5" color="error" gutterBottom>
        Error: {error || "Unknown Error"}
      </Typography>
      {message && (
        <Typography variant="body1" gutterBottom>
          <strong>Message:</strong> {message}
        </Typography>
      )}
      {error_line && (
        <Typography variant="body1" gutterBottom>
          <strong>Error Line:</strong> {error_line} (Line {error_line_number})
        </Typography>
      )}
      {stack_trace && (
        <Paper
          elevation={7}
          style={{ padding: "1rem", marginTop: "1rem", overflowX: "auto" }}
        >
          <Typography variant="subtitle1" gutterBottom>
            <strong>Stack Trace:</strong>
          </Typography>
          <Typography variant="body2" component="pre">
            {stack_trace}
          </Typography>
        </Paper>
      )}
    </Box>
  );
};

export const CodeTableFound: React.FC<{
  tableId: string;
}> = ({ tableId }) => {
  const tableSpec = useTableSpec(tableId);
  const [selectedTab, setSelectedTab] = useState(0);
  const sideEffectTable = tableSpec?.["code-type"] === "side-effect";
  const isError = tableSpec?.["execute-error"] ? true : false;

  return (
    <>
      {tableSpec && (
        <>
          <Tabs value={selectedTab}>
            {isError ? (
              <Tab
                color="primary"
                label="Error"
                onClick={() => setSelectedTab(0)}
              />
            ) : (
              <Tab
                color="primary"
                label="Table"
                onClick={() => setSelectedTab(0)}
              />
            )}
            <Tab
              color="primary"
              label="Code"
              onClick={() => setSelectedTab(1)}
            />
            <Tab
              color="primary"
              label="Inputs"
              onClick={() => setSelectedTab(2)}
            />
            <Tab
              color="primary"
              label="Run History"
              onClick={() => setSelectedTab(3)}
            />
            {sideEffectTable && (
              <Tab
                color="primary"
                label="Update Trigger"
                onClick={() => setSelectedTab(4)}
              />
            )}
          </Tabs>
          {selectedTab === 0 ? (
            isError ? (
              <ErrorTab tableSpec={tableSpec!} />
            ) : (
              <DataTable tableId={tableId} />
            )
          ) : selectedTab === 1 ? (
            <CodeTab tableSpec={tableSpec} />
          ) : selectedTab === 2 ? (
            <InputsTab tableSpec={tableSpec} />
          ) : selectedTab === 3 ? (
            <RunHistoryTab tableSpec={tableSpec} />
          ) : selectedTab === 4 ? (
            <UpdatesTab tableSpec={tableSpec} />
          ) : (
            <Typography>Error: Tab not found</Typography>
          )}
        </>
      )}
    </>
  );
};

export const CodeTableNotFound: React.FC<{}> = () => {
  return (
    <Paper elevation={9} sx={{ padding: 2 }}>
      <Typography color={"error"}>Table Not Found</Typography>
    </Paper>
  );
};

export const CodeTable: React.FC<{
  tableId: string;
}> = ({ tableId }) => {
  const isTableNotFound = useIsTableNotFound();
  const tableNotFound = isTableNotFound(tableId);

  return tableNotFound ? (
    <CodeTableNotFound />
  ) : (
    <CodeTableFound tableId={tableId} />
  );
};

export const SearchTable: React.FC<{
  tableId: string;
}> = ({ tableId }) => {
  const tableType = useTableType(tableId);

  return (
    <Stack spacing={2}>
      {tableType === TableType.CODE_TABLE && <CodeTable tableId={tableId} />}
      {tableType === TableType.DATA_TABLE && <DataTable tableId={tableId} />}
    </Stack>
  );
};
