import { useEffect, useState } from "react";
import { useSelectedPage } from "../../../hook";
import {
  useGetConnector,
  useGetConnectorStatus,
  useSelectedPullConnector,
} from "../../../hook/pullConnector";
import { NoteBlockContainer, NotePageContainer } from "../../note";
import { ConnectorStatus } from "../../../types/pullConnector";
import { Chip, Grid, Typography } from "@mui/material";

export const PullConnectorPage: React.FC = () => {
  const [selectedPage, setSelectedNote] = useSelectedPage();
  const [selectedPullConnector, setSelectedPullConnector] =
    useSelectedPullConnector();
  const pageName = selectedPullConnector!;
  const getConnectorStatus = useGetConnectorStatus();
  const [connectorStatus, setConnectorStatus] = useState<
    ConnectorStatus | undefined
  >();
  const getConnector = useGetConnector();
  const connector = getConnector(selectedPullConnector!);
  const schemaName = connector?.card.schema;

  useEffect(() => {
    setConnectorStatus(getConnectorStatus(selectedPullConnector!));
  }, [selectedPullConnector]);

  return (
    <NotePageContainer pageName={schemaName!}>
      <NoteBlockContainer name={"Status"}>
        {connectorStatus && (
          <Grid container spacing={1} sx={{ width: "20rem" }}>
            <Grid item xs={6}>
              <Typography variant="body2">Setup State:</Typography>
            </Grid>
            <Grid item xs={6}>
              <Chip
                label={connectorStatus.setup_state}
                color={
                  connectorStatus.setup_state === "incomplete"
                    ? "error"
                    : connectorStatus.setup_state === "connected"
                    ? "success"
                    : "default"
                }
                size="small"
              />
            </Grid>
            <Grid item xs={6}>
              <Typography variant="body2">Schema Status:</Typography>
            </Grid>
            <Grid item xs={6}>
              <Chip
                label={connectorStatus.schema_status}
                color={
                  connectorStatus.schema_status === "ready"
                    ? "success"
                    : "default"
                }
                size="small"
              />
            </Grid>

            <Grid item xs={6}>
              <Typography variant="body2">Sync State:</Typography>
            </Grid>
            <Grid item xs={6}>
              <Chip
                label={connectorStatus.sync_state}
                color="default"
                size="small"
              />
            </Grid>

            <Grid item xs={6}>
              <Typography variant="body2">Update State:</Typography>
            </Grid>
            <Grid item xs={6}>
              <Chip
                label={connectorStatus.update_state}
                color="default"
                size="small"
              />
            </Grid>

            <Grid item xs={6}>
              <Typography variant="body2">Historical Sync:</Typography>
            </Grid>
            <Grid item xs={6}>
              <Chip
                label={connectorStatus.is_historical_sync ? "Yes" : "No"}
                color={"default"}
                size="small"
              />
            </Grid>
          </Grid>
        )}
      </NoteBlockContainer>
    </NotePageContainer>
  );
};
