import * as Sentry from "@sentry/react";
import { toEDNString } from "edn-data";
import * as R from "ramda";
import {
  App,
  AppUser,
  PaymentMethod,
  PushConnectorData,
  UserRole,
} from "./reducer";
import { NotePage, pageBlockToEdnVal } from "./types/note";
import { ConnectorTableResponse } from "./types/pullConnector";
import {
  GetTableRunHistoryResponse,
  Table,
  TableLogs,
  TableRunSuccess,
  TableSpec,
  TableTrigger,
} from "./types/table";

const omegaUrl = process.env.REACT_APP_OMEGA_URL;
const omegaEnv = process.env.REACT_APP_OMEGA_ENV;

const constructUrlParams = (
  baseUrl: string,
  params: { [key: string]: string }
) => {
  const queryString = Object.entries(params)
    .map(
      ([key, value]) =>
        `${encodeURIComponent(key)}=${encodeURIComponent(value)}`
    )
    .join("&");

  return `${baseUrl}?${queryString}`;
};

export const queryOmega = async (query: string) => {
  const omegaEnvHeader = omegaEnv ? { oql_env_name: omegaEnv } : {};
  const headers = { ...omegaEnvHeader, "Content-Type": "text/plain" };
  const fullUrl = constructUrlParams(
    `${omegaUrl}/query_clj`,
    omegaEnvHeader as any
  );
  return fetch(fullUrl, {
    method: "POST",
    headers: headers as any,
    body: query,
  })
    .then(async (res) => {
      if (res.status === 500) {
        const e = new Error("API 500 Error");
        const resBody = await res.json();
        Sentry.captureException(e, { extra: { responseBody: resBody } });
        throw e;
      }
      return res;
    })
    .then((res) => res.json())
    .then((jsonResponse) => jsonResponse["result"]);
};

export const queryOmegaSingleResult = async (query: string) => {
  const response = await queryOmega(query);
  return response["data"][0][0];
};

export const getConnectorTypes = async () => {
  const query = `
      (datastore Api "omega/query-omega/public/api")
      (Api/get-connector-types {} Response)
      (return [Response])
    `;
  const response = await queryOmegaSingleResult(query);
  return response["connector-types"];
};

export const getNotebook: (config: { appId: string }) => Promise<any> = async ({
  appId,
}) => {
  const ednString = toEDNString({
    map: [["app-id", appId]],
  });
  const query = `
      (datastore Api "omega/query-omega/public/api")
      (Api/get-pages ${ednString} Response)
      (return [Response])
    `;
  const response = await queryOmegaSingleResult(query);
  return response;
};

export const getTable: (config: {
  appId: string;
  tableId: string;
}) => Promise<Table> = async ({ appId, tableId }) => {
  const ednString = toEDNString({
    map: [
      ["app-id", appId],
      ["table-id", tableId],
    ],
  });
  const query = `
      (datastore Api "omega/query-omega/public/api")
      (Api/search-table ${ednString} Response)
      (return [Response])
    `;
  const response = await queryOmegaSingleResult(query);
  return response;
};

export const getTableSpec: (config: {
  appId: string;
  tableId: string;
}) => Promise<TableSpec> = async ({ appId, tableId }) => {
  const ednString = toEDNString({
    map: [
      ["app-id", appId],
      ["table-id", tableId],
    ],
  });
  const query = `
      (datastore Api "omega/query-omega/public/api")
      (Api/get-table-spec ${ednString} Response)
      (return [Response])
    `;
  const response = await queryOmegaSingleResult(query);
  return response;
};

export const getTableLogs: (config: {
  appId: string;
  tableId: string;
}) => Promise<TableLogs> = async ({ appId, tableId }) => {
  const ednString = toEDNString({
    map: [
      ["app-id", appId],
      ["table-id", tableId],
    ],
  });
  const query = `
      (datastore Api "omega/query-omega/public/api")
      (Api/get-table-logs ${ednString} Response)
      (return [Response])
    `;
  const response = await queryOmegaSingleResult(query);
  return response;
};

export interface GetAppsResponse {
  apps: App[];
  email?: string;
  "selected-app"?: string;
}

export const getApps: (config: {
  authToken: string;
}) => Promise<GetAppsResponse> = async ({ authToken }) => {
  const ednString = toEDNString({
    map: [["auth-token", authToken]],
  });
  const query = `
      (datastore Api "omega/query-omega/public/api")
      (Api/get-apps ${ednString} Response)
      (return [Response])
    `;
  const response = await queryOmegaSingleResult(query);
  return response;
};

export interface GetAllWorkspacesResponse {
  apps: App[];
}

export const getAllWorkspaces: (config?: {}) => Promise<GetAllWorkspacesResponse> =
  async (config = {}) => {
    const query = `
      (datastore Api "omega/query-omega/public/api")
      (Api/get-all-workspaces {} Response)
      (return [Response])
    `;
    const response = await queryOmegaSingleResult(query);
    return response;
  };

export interface GetAllUsersResponse {
  users: AppUser[];
}

export const getAllUsers: (config?: {}) => Promise<GetAllUsersResponse> =
  async (config = {}) => {
    const query = `
      (datastore Api "omega/query-omega/public/api")
      (Api/all-users {} Response)
      (return [Response])
    `;
    const response = await queryOmegaSingleResult(query);
    return response;
  };

export interface GetWorkpaceUsersResponse {
  users: AppUser[];
}

export const getWorkspaceUsers: (config: {
  appId: string;
}) => Promise<GetWorkpaceUsersResponse> = async ({ appId }) => {
  const ednString = toEDNString({
    map: [["app-id", appId]],
  });
  const query = `
      (datastore Api "omega/query-omega/public/api")
      (Api/workspace-users ${ednString} Response)
      (return [Response])
    `;
  const response = await queryOmegaSingleResult(query);
  return response;
};

export const addWorkspaceUser: (config: {
  appId: string;
  email: string;
  name: string;
}) => Promise<any> = async ({ appId, email, name }) => {
  const ednString = toEDNString({
    map: [
      ["app-id", appId],
      ["email", email],
      ["name", name],
    ],
  });
  const query = `
      (datastore Api "omega/query-omega/public/api")
      (Api/add-workspace-user ${ednString} Response)
      (return [Response])
    `;
  const response = await queryOmegaSingleResult(query);
  return response;
};

export const setSelectedApp: (config: {
  appId: string;
  email: string;
}) => Promise<any> = async ({ appId, email }) => {
  const ednString = toEDNString({
    map: [
      ["app-id", appId],
      ["email", email],
    ],
  });
  const query = `
      (datastore Api "omega/query-omega/public/api")
      (Api/set-selected-app ${ednString} Response)
      (return [Response])
    `;
  const response = await queryOmegaSingleResult(query);
  return response;
};

export const deleteWorkspaceUser: (config: {
  appId: string;
  email: string;
}) => Promise<any> = async ({ appId, email }) => {
  const ednString = toEDNString({
    map: [
      ["app-id", appId],
      ["email", email],
    ],
  });
  const query = `
      (datastore Api "omega/query-omega/public/api")
      (Api/delete-workspace-user ${ednString} Response)
      (return [Response])
    `;
  const response = await queryOmegaSingleResult(query);
  return response;
};

export const deleteWorkspace: (config: {
  appId: string;
}) => Promise<any> = async ({ appId }) => {
  const ednString = toEDNString({
    map: [["app-id", appId]],
  });
  const query = `
      (datastore Api "omega/query-omega/public/api")
      (Api/delete-app ${ednString} Response)
      (return [Response])
    `;
  const response = await queryOmegaSingleResult(query);
  return response;
};

export const addWorkspace: (config: {
  ownerEmail: string;
  ownerName: string;
  appName: string;
}) => Promise<any> = async ({ ownerName, appName, ownerEmail }) => {
  const ednString = toEDNString({
    map: [
      ["owner-email", ownerEmail],
      ["owner-name", ownerName],
      ["app-name", appName],
    ],
  });
  const query = `
      (datastore Api "omega/query-omega/public/api")
      (Api/add-app ${ednString} Response)
      (return [Response])
    `;
  const response = await queryOmegaSingleResult(query);
  return response;
};

export const getConnectors: (config: {
  appId: string;
}) => Promise<any> = async ({ appId }) => {
  const ednString = toEDNString({
    map: [["app-id", appId]],
  });
  const query = `
      (datastore Api "omega/query-omega/public/api")
      (Api/get-connectors ${ednString} Response)
      (return [Response])
    `;
  const response = await queryOmegaSingleResult(query);
  return response;
};

export const deletePullConnector: (config: {
  appId: string;
  connectorId: string;
}) => Promise<any> = async ({ appId, connectorId }) => {
  const ednString = toEDNString({
    map: [
      ["app-id", appId],
      ["connector-id", connectorId],
    ],
  });
  const query = `
      (datastore Api "omega/query-omega/public/api")
      (Api/delete-connector ${ednString} Response)
      (return [Response])
    `;
  const response = await queryOmegaSingleResult(query);
  return response;
};

export const getPushConnectors: (config: {
  appId: string;
}) => Promise<any> = async ({ appId }) => {
  const ednString = toEDNString({
    map: [["app-id", appId]],
  });
  const query = `
      (datastore Api "omega/query-omega/public/api")
      (Api/get-push-connectors ${ednString} Response)
      (return [Response])
    `;
  const response = await queryOmegaSingleResult(query);
  return response;
};

export interface GetRolesResponse {
  email: string;
  roles: UserRole[];
}

export const getRoles: (config: {
  email: string;
}) => Promise<GetRolesResponse> = async ({ email }) => {
  const ednString = toEDNString({
    map: [["email", email]],
  });
  const query = `
      (datastore Api "omega/query-omega/public/api")
      (Api/get-roles ${ednString} Response)
      (return [Response])
    `;
  const response = await queryOmegaSingleResult(query);
  return response;
};

export const getConnectorTables: (config: {
  appId: string;
  connectorId: string;
}) => Promise<ConnectorTableResponse> = async ({ appId, connectorId }) => {
  const ednString = toEDNString({
    map: [
      ["app-id", appId],
      ["connector-id", connectorId],
    ],
  });
  const query = `
      (datastore Api "omega/query-omega/public/api")
      (Api/get-connector-tables ${ednString} Response)
      (return [Response])
    `;
  const response = await queryOmegaSingleResult(query);
  return response;
};

export const deleteConnector: (config: {
  schema: string;
}) => Promise<any> = async ({ schema }) => {
  const ednString = toEDNString({
    map: [["schema", schema]],
  });
  const query = `
      (datastore Api "omega/query-omega/public/api")
      (Api/delete-connector ${ednString} Response)
      (return [Response])
    `;
  const response = await queryOmegaSingleResult(query);
  return response;
};

export const createPushConnector: (config: {
  appId: string;
  pushConnector: PushConnectorData;
}) => Promise<any> = async ({ appId, pushConnector }) => {
  const ednString = toEDNString({
    map: [
      ["app-id", appId],
      ["push-connector", { map: Object.entries(pushConnector) }],
    ],
  });
  const query = `
      (datastore Api "omega/query-omega/public/api")
      (Api/create-push-connector ${ednString} Response)
      (return [Response])
    `;
  const response = await queryOmegaSingleResult(query);
  return response;
};

export const editPushConnector: (config: {
  appId: string;
  pushConnectorId: string;
  pushConnector: PushConnectorData;
}) => Promise<any> = async ({ appId, pushConnectorId, pushConnector }) => {
  const ednString = toEDNString({
    map: [
      ["app-id", appId],
      ["push-connector-id", pushConnectorId],
      ["push-connector", { map: Object.entries(pushConnector) }],
    ],
  });
  const query = `
      (datastore Api "omega/query-omega/public/api")
      (Api/edit-push-connector ${ednString} Response)
      (return [Response])
    `;
  const response = await queryOmegaSingleResult(query);
};

export const deletePushConnector: (config: {
  appId: string;
  pushConnectorId: string;
}) => Promise<any> = async ({ appId, pushConnectorId }) => {
  const ednString = toEDNString({
    map: [
      ["app-id", appId],
      ["push-connector-id", pushConnectorId],
    ],
  });
  const query = `
      (datastore Api "omega/query-omega/public/api")
      (Api/delete-push-connector ${ednString} Response)
      (return [Response])
    `;
  const response = await queryOmegaSingleResult(query);
  return response;
};

export const deletePageBlock: (
  pageId: string,
  blockIndex: number
) => Promise<any> = async (pageId, blockIndex) => {
  const ednString = toEDNString({
    map: [
      ["note-id", pageId],
      ["block-index", blockIndex],
    ],
  });
  const query = `
      (datastore Api "omega/query-omega/public/api")
      (Api/delete-note-block ${ednString} Response)
      (return [Response])
    `;
  const response = await queryOmegaSingleResult(query);
  return response["new-note"];
};

export const createConnectCard: (config: {
  schema: string;
  appId: string;
}) => Promise<any> = async ({ schema, appId }) => {
  const ednString = toEDNString({
    map: [
      ["app-id", appId],
      ["schema", schema],
    ],
  });
  const query = `
      (datastore Api "omega/query-omega/public/api")
      (Api/create-connect-card ${ednString} Response)
      (return [Response])
    `;
  const response = await queryOmegaSingleResult(query);
  return response;
};

export const createConversation: (config: {
  appId: string;
}) => Promise<any> = async ({ appId }) => {
  const ednString = toEDNString({
    map: [["app-id", appId]],
  });
  const query = `
      (datastore Api "omega/query-omega/public/api")
      (Api/create-conversation ${ednString} Response)
      (return [Response])
    `;
  const response = await queryOmegaSingleResult(query);
  return response;
};

export const replyConversation: (config: {
  content: string;
  chatId: string;
}) => Promise<any> = async ({ content, chatId }) => {
  const ednString = toEDNString({
    map: [
      ["content", content],
      ["chat-id", chatId],
    ],
  });
  const query = `
      (datastore Api "omega/query-omega/public/api")
      (Api/reply-conversation ${ednString} Response)
      (return [Response])
    `;
  const response = await queryOmegaSingleResult(query);
  return response;
  // return new Promise((resolve, reject) => {
  //   setTimeout(() => {
  //     resolve({
  //       "chat-id": "be93e9d9-a2b4-4716-9228-f76fd182010b",
  //       reply:
  //         'I have created a script that processes the "transformed_go_high_level_data" table to extract full names. Here\'s what the script does:\n\n1. **Extracts the Input Table**: It takes the "transformed_go_high_level_data" table, which contains columns like email, first name, and last name.\n2. **Creates a Full Name Column**: It combines the first name and last name columns to create a new column called "full_name".\n3. **Selects Required Columns**: It then selects only the email and full_name columns to create a new table.\n4. **Stores the Result**: The new table, named "full_names_output", contains the email and full names of the contacts.\n\nThe result is saved in a variable called `result`, which contains the new table with the specified columns.',
  //     });
  //   }, 2000);
  // });
};

export const deleteTable: (config: {
  tableId: string;
}) => Promise<any> = async ({ tableId }) => {
  const ednString = toEDNString({
    map: [["table-id", tableId]],
  });
  const query = `
      (datastore Api "omega/query-omega/public/api")
      (Api/delete-table ${ednString} Response)
      (return [Response])
    `;
  const response = await queryOmegaSingleResult(query);
  return response;
};

export const fetchTableCount: (config: {
  tableId: string;
  appId: string;
}) => Promise<{
  "table-id": string;
  "app-id": string;
  count: number;
}> = async ({ tableId, appId }) => {
  const ednString = toEDNString({
    map: [
      ["table-id", tableId],
      ["app-id", appId],
    ],
  });
  const query = `
      (datastore Api "omega/query-omega/public/api")
      (Api/get-table-count ${ednString} Response)
      (return [Response])
    `;
  const response = await queryOmegaSingleResult(query);
  return response;
};

export const fetchTablePage: (config: {
  tableId: string;
  appId: string;
  index: number;
  pageSize: number;
}) => Promise<{
  "table-id": string;
  "app-id": string;
  index: number;
  "page-size": number;
  rows: string[][];
}> = async ({ tableId, appId, index, pageSize }) => {
  const ednString = toEDNString({
    map: [
      ["table-id", tableId],
      ["app-id", appId],
      ["index", index],
      ["page-size", pageSize],
    ],
  });
  const query = `
      (datastore Api "omega/query-omega/public/api")
      (Api/get-table-page ${ednString} Response)
      (return [Response])
    `;
  const response = await queryOmegaSingleResult(query);
  return response;
};

export interface GetPaymentMethodsResponse {
  email: string;
  "stripe-customer-id": string;
  methods: PaymentMethod[];
  "default-payment-method": string;
}

export const getPaymentMethods: (config: {
  email: string;
}) => Promise<GetPaymentMethodsResponse> = async ({ email }) => {
  const ednString = toEDNString({
    map: [["email", email]],
  });
  const query = `
        (datastore Api "omega/query-omega/public/api")
        (Api/get-payment-methods ${ednString} Response)
        (return [Response])
      `;
  const response = await queryOmegaSingleResult(query);
  return response;
};

export interface CreateSetupIntentResponse {
  email: string;
  "stripe-customer-id": string;
  "client-secret": string;
}

export const createSetupIntent: (config: {
  email: string;
}) => Promise<CreateSetupIntentResponse> = async ({ email }) => {
  const ednString = toEDNString({
    map: [["email", email]],
  });
  const query = `
        (datastore Api "omega/query-omega/public/api")
        (Api/create-setup-intent ${ednString} Response)
        (return [Response])
      `;
  const response = await queryOmegaSingleResult(query);
  return response;
};

export interface CreateSubscriptionResponse {
  "subscription-id": string;
  "customer-id": string;
}

export const createSubscription: (config: {
  email: string;
}) => Promise<CreateSubscriptionResponse> = async ({ email }) => {
  const ednString = toEDNString({
    map: [["email", email]],
  });
  const query = `
        (datastore Api "omega/query-omega/public/api")
        (Api/create-subscription ${ednString} Response)
        (return [Response])
      `;
  const response = await queryOmegaSingleResult(query);
  return response;
};

export interface GetSubscriptionResponse {
  "subscription-id"?: string;
  "not-found"?: boolean;
  "current-period-start"?: number;
  "current-period-end"?: number;
  "cancel-at-period-end"?: boolean;
  status?: string;
}

export const getSubscription: (config: {
  email: string;
}) => Promise<GetSubscriptionResponse> = async ({ email }) => {
  const ednString = toEDNString({
    map: [["email", email]],
  });
  const query = `
        (datastore Api "omega/query-omega/public/api")
        (Api/get-subscription ${ednString} Response)
        (return [Response])
      `;
  const response = await queryOmegaSingleResult(query);
  return response;
};

export const cancelSubscription: (config: {
  email: string;
}) => Promise<any> = async ({ email }) => {
  const ednString = toEDNString({
    map: [["email", email]],
  });
  const query = `
        (datastore Api "omega/query-omega/public/api")
        (Api/cancel-subscription ${ednString} Response)
        (return [Response])
      `;
  const response = await queryOmegaSingleResult(query);
  return response;
};

export const setDefaultPaymentMethod: (config: {
  email: string;
  paymentMethod: string;
}) => Promise<any> = async ({ email, paymentMethod }) => {
  const ednString = toEDNString({
    map: [
      ["email", email],
      ["paymentMethod", paymentMethod],
    ],
  });
  const query = `
        (datastore Api "omega/query-omega/public/api")
        (Api/set-default-payment-method ${ednString} Response)
        (return [Response])
      `;
  const response = await queryOmegaSingleResult(query);
  return response;
};

export const deletePaymentMethod: (config: {
  paymentMethod: string;
}) => Promise<any> = async ({ paymentMethod }) => {
  const ednString = toEDNString({
    map: [["paymentMethod", paymentMethod]],
  });
  const query = `
        (datastore Api "omega/query-omega/public/api")
        (Api/delete-payment-method ${ednString} Response)
        (return [Response])
      `;
  const response = await queryOmegaSingleResult(query);
  return response;
};

export const activateSubscription: (config: {
  email: string;
}) => Promise<any> = async ({ email }) => {
  const ednString = toEDNString({
    map: [["email", email]],
  });
  const query = `
        (datastore Api "omega/query-omega/public/api")
        (Api/activate-subscription ${ednString} Response)
        (return [Response])
      `;
  const response = await queryOmegaSingleResult(query);
  return response;
};

export const runTable: (config: {
  tableId: string;
  appId: string;
}) => Promise<TableRunSuccess> = async ({ tableId, appId }) => {
  const ednString = toEDNString({
    map: [
      ["table-id", tableId],
      ["app-id", appId],
    ],
  });
  const query = `
        (datastore Api "omega/query-omega/public/api")
        (Api/run-table ${ednString} Response)
        (return [Response])
      `;
  const response = await queryOmegaSingleResult(query);
  return response;
};

export const setTableTrigger: (config: {
  tableId: string;
  trigger: TableTrigger;
}) => Promise<TableRunSuccess> = async ({ tableId, trigger }) => {
  const ednString = toEDNString({
    map: [
      ["table-id", tableId],
      ["trigger", trigger],
    ],
  });
  const query = `
        (datastore Api "omega/query-omega/public/api")
        (Api/set-table-trigger ${ednString} Response)
        (return [Response])
      `;
  const response = await queryOmegaSingleResult(query);
  return response;
};

export const setTableCode: (config: {
  tableId: string;
  code: string;
}) => Promise<TableRunSuccess> = async ({ tableId, code }) => {
  const ednString = toEDNString({
    map: [
      ["table-id", tableId],
      ["code", code],
    ],
  });
  const query = `
        (datastore Api "omega/query-omega/public/api")
        (Api/set-table-code ${ednString} Response)
        (return [Response])
      `;
  const response = await queryOmegaSingleResult(query);
  return response;
};

export const setPageData: (config: {
  pageId: string;
  appId: string;
  pageData: NotePage;
}) => Promise<any> = async ({ pageId, appId, pageData }) => {
  const ednString = toEDNString({
    map: [
      ["page-id", pageId],
      ["app-id", appId],
      [
        "page-data",
        {
          map: [
            ["name", pageData.name],
            ["blocks", pageData.blocks.map(pageBlockToEdnVal)],
          ],
        },
      ],
    ],
  });
  const query = `
        (datastore Api "omega/query-omega/public/api")
        (Api/set-page-data ${ednString} Response)
        (return [Response])
      `;
  const response = await queryOmegaSingleResult(query);
  return response;
};

export const getTableRunHistory: (config: {
  tableId: string;
  appId: string;
}) => Promise<GetTableRunHistoryResponse> = async ({ appId, tableId }) => {
  const ednString = toEDNString({
    map: [
      ["table-id", tableId],
      ["app-id", appId],
    ],
  });
  const query = `
        (datastore Api "omega/query-omega/public/api")
        (Api/table-run-history ${ednString} Response)
        (return [Response])
      `;
  const response = await queryOmegaSingleResult(query).then(
    (response: GetTableRunHistoryResponse) => {
      const runs = response["table-runs"];
      const sortedRuns = R.reverse(R.sortBy(R.prop("timestamp"), runs));
      const newResponse = R.assoc("table-runs", sortedRuns, response);
      return newResponse;
    }
  );
  return response;
};
