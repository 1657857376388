import { useAuth0 } from "@auth0/auth0-react";
import { Box, Button } from "@mui/material";
import { addWorkspace } from "../../api";
import { useFetchAppId } from "../../hook";
import { NoteBlockContainer } from "../note";

export const NoWorkspacePage: React.FC = () => {
  const { user } = useAuth0();
  const email = user?.email!;
  const fetchAppId = useFetchAppId();

  const initWorkspace = () => {
    const appName = `${email} workspace`;
    addWorkspace({ ownerEmail: email, appName, ownerName: email }).then(
      (response) => {
        console.log(`INIT_WORKSPACE ${email}`);
        fetchAppId();
      }
    );
  };

  return (
    <NoteBlockContainer name="No Workspace">
      <Box sx={{ display: "flex", flexDirection: "column" }}>
        No Workspaces are associated with this account. You can start a 30-day
        free trial. No credit card required.
        <Box>
          <Button onClick={() => initWorkspace()}>Start Free Trial</Button>
        </Box>
      </Box>
    </NoteBlockContainer>
  );
};
