import { useAuth0 } from "@auth0/auth0-react";
import * as R from "ramda";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  GetAppsResponse,
  createConversation,
  deleteTable,
  getApps,
  getConnectorTypes,
  getConnectors,
  getNotebook,
  getRoles,
  replyConversation as replyConversationApi,
} from "./api";
import {
  useFetchNotebookTables,
  useNotebook,
  useSelectMainNote,
} from "./hook/note";
import {
  useConnectorTypes,
  useConnectors,
  useHandleConnectorsResponse,
} from "./hook/pullConnector";
import { useFetchPushConnectors } from "./hook/pushConnector";
import { useHandleGetRolesResponse } from "./hook/role";
import { useSubscriptionStatus } from "./hook/settings";
import {
  ActionType,
  App,
  AppDispatch,
  Message,
  MessageRole,
  PageContentType,
  RootState,
  SelectedPage,
} from "./reducer";
import { TableSpecMap } from "./types/table";

export const useAppId: () => [
  string | undefined,
  (notebook: string) => void
] = () => {
  const appId = useSelector((state: RootState) => state.main.appId);
  const dispatch = useDispatch<AppDispatch>();
  const setNotebook = (appId: string) =>
    dispatch({ type: ActionType.SET_APP_ID, appId });
  return [appId, setNotebook];
};

export const useApps: () => [App[], (apps: App[]) => void] = () => {
  const appId = useSelector((state: RootState) => state.main.apps);
  const dispatch = useDispatch<AppDispatch>();
  const setNotebook = (apps: App[]) =>
    dispatch({ type: ActionType.SET_APPS, apps });
  return [appId, setNotebook];
};

export const useSelectedPage: () => [
  SelectedPage,
  (selectedPage: SelectedPage) => void
] = () => {
  const selectedPage = useSelector(
    (state: RootState) => state.main.selectedPage
  );
  const dispatch = useDispatch<AppDispatch>();
  const setSelectedPage = (selectedPage: SelectedPage) =>
    dispatch({ type: ActionType.SET_SELECTED_PAGE, selectedPage });
  return [selectedPage, setSelectedPage];
};

export const useChatId: () => [
  string | undefined,
  (chatId: string) => void
] = () => {
  const chatId = useSelector((state: RootState) => state.main.chatId);
  const dispatch = useDispatch<AppDispatch>();
  const setChatId = (chatId: string) =>
    dispatch({ type: ActionType.SET_CHAT_ID, chatId });
  return [chatId, setChatId];
};

export const useChatDrawerOpen: () => [
  boolean,
  (chatDrawerOpen: boolean) => void
] = () => {
  const chatDrawerOpen = useSelector(
    (state: RootState) => state.main.chatDrawerOpen
  );
  const dispatch = useDispatch<AppDispatch>();
  const setChatDrawerOpen = (chatDrawerOpen: boolean) =>
    dispatch({ type: ActionType.SET_CHAT_DRAWER_OPEN, chatDrawerOpen });
  return [chatDrawerOpen, setChatDrawerOpen];
};

export const useToggleChatDrawerOpen: () => () => void = () => {
  const chatDrawerOpen = useSelector(
    (state: RootState) => state.main.chatDrawerOpen
  );
  const dispatch = useDispatch<AppDispatch>();
  const toggleChatDrawerOpen = () =>
    dispatch({
      type: ActionType.SET_CHAT_DRAWER_OPEN,
      chatDrawerOpen: !chatDrawerOpen,
    });
  return toggleChatDrawerOpen;
};

export const useSideNavOpen: () => [
  boolean,
  (sideNavOpen: boolean) => void
] = () => {
  const sideNavOpen = useSelector((state: RootState) => state.main.sideNavOpen);
  const dispatch = useDispatch<AppDispatch>();
  const setSideNavOpen = (sideNavOpen: boolean) =>
    dispatch({ type: ActionType.SET_SIDE_NAV_OPEN, sideNavOpen });
  return [sideNavOpen, setSideNavOpen];
};

export const useToggleSideNavOpen: () => () => void = () => {
  const sideNavOpen = useSelector((state: RootState) => state.main.sideNavOpen);
  const dispatch = useDispatch<AppDispatch>();
  const toggleSideNavOpen = () =>
    dispatch({
      type: ActionType.SET_SIDE_NAV_OPEN,
      sideNavOpen: !sideNavOpen,
    });
  return toggleSideNavOpen;
};

export const useMessages: () => [
  Message[],
  (messages: Message[]) => void
] = () => {
  const messages = useSelector((state: RootState) => state.main.messages);
  const [appId, setAppId] = useAppId();
  const dispatch = useDispatch<AppDispatch>();
  const setMessages = (messages: Message[]) =>
    dispatch({ type: ActionType.SET_MESSAGES, messages });

  useEffect(() => {
    setMessages([]);
  }, [appId]);

  return [messages, setMessages];
};

export const useAddMessage: () => (message: Message) => void = () => {
  const dispatch = useDispatch<AppDispatch>();
  const addMessage = (message: Message) =>
    dispatch({ type: ActionType.ADD_MESSAGE, message });
  return addMessage;
};

export const useNoWorkspaces: () => [
  boolean,
  (noWorkspaces: boolean) => void
] = () => {
  const noWorkspaces = useSelector(
    (state: RootState) => state.main.noWorkspaces ?? false
  );
  const dispatch = useDispatch();
  const setNoWorkspaces = (noWorkspaces: boolean) => {
    dispatch({
      type: ActionType.SET_NO_WORKSPACES,
      noWorkspaces,
    });
  };
  return [noWorkspaces, setNoWorkspaces];
};

export const useAppOwner: () => [
  string | undefined,
  (appOwner: string | undefined) => void
] = () => {
  const appOwner = useSelector((state: RootState) => state.main.appOwner);
  const dispatch = useDispatch<AppDispatch>();
  const setAppOwner = (appOwner: string | undefined) =>
    dispatch({ type: ActionType.SET_APP_OWNER, appOwner });
  return [appOwner, setAppOwner];
};

export const useFetchAppId: () => () => Promise<void> = () => {
  const [appId, setAppId] = useAppId();
  const [authTokenMaybe, setAuthToken] = useAuthToken();
  const [selectedPage, setSelectedPage] = useSelectedPage();
  const [noWorkspaces, setNoWorkspaces] = useNoWorkspaces();
  const [appOwner, setAppOwner] = useAppOwner();
  const [apps, setApps] = useApps();
  const authToken = authTokenMaybe!;

  const setAppFromResponse = (response: GetAppsResponse) => {
    const selectedApp = response["selected-app"]
      ? response.apps.find((app: App) => app.id === response["selected-app"])
      : undefined;
    const selectedAppId = selectedApp?.id;
    const appOwner = selectedApp?.["owner-email"];
    setAppOwner(appOwner);
    const apps = response.apps;
    setApps(apps);
    selectedAppId ? setAppId(selectedAppId) : setNoWorkspaces(true);
  };

  return async () => {
    setSelectedPage({ contentType: PageContentType.LOADING_ACCOUNT });
    getApps({ authToken }).then(setAppFromResponse);
  };
};

export const useTablePage: (config: {
  tableId?: string;
  index: number;
}) => string[][] | undefined = ({ tableId, index }) => {
  const tablePages = useSelector((state: RootState) =>
    tableId ? state.main.tablePages[tableId]?.[index] : undefined
  );
  return tablePages;
};

export const useSetTableSpecs: () => (
  tableSpecs: TableSpecMap
) => void = () => {
  const dispatch = useDispatch();
  const setTableSpecs = (tableSpecs: TableSpecMap) => {
    dispatch({ type: ActionType.SET_TABLE_SPECS, tableSpecs });
  };
  return setTableSpecs;
};

export const useHandleCreateConversation: () => (
  createConversationResponse: any
) => void = () => {
  const [chatId, setChatId] = useChatId();
  const replyConversation = useReplyConversation();

  const handleCreateConversation = (createConversationResponse: any) => {
    const chatId = createConversationResponse["chat-id"];
    setChatId(chatId);
    // replyConversation({
    //   content:
    //     "Give me the full names from the transformed go high level contacts table.",
    //   chatId,
    // });
  };
  return handleCreateConversation;
};

export const useFetchAppData: () => void = () => {
  const [notebook, setNotebook] = useNotebook();
  const [appId, setAppId] = useAppId();
  const [chatId, setChatId] = useChatId();
  const [connectors, setConnectors] = useConnectors();
  const [connectorTypes, setConnectorTypes] = useConnectorTypes();
  const handleConnectorsResponse = useHandleConnectorsResponse();
  const handleCreateConversation = useHandleCreateConversation();
  const fetchPushConnectors = useFetchPushConnectors();
  const selectMainNote = useSelectMainNote();
  const [sideNav, setSideNavOpen] = useSideNavOpen();
  const [selectedPage, setSelectedPage] = useSelectedPage();
  const handleGetRolesResponse = useHandleGetRolesResponse();
  const { user } = useAuth0();
  const { hasAccess, loading } = useSubscriptionStatus();
  const fetchNotebookTables = useFetchNotebookTables();
  const email = user?.email;

  useEffect(() => {
    if (loading) return;

    if (!hasAccess) {
      setSelectedPage({
        contentType: PageContentType.SETTINGS,
      });
    }

    if (!notebook) return;

    if (hasAccess) {
      // setSelectedPage({
      //   contentType: PageContentType.SETTINGS,
      // });
      selectMainNote();
    }
  }, [loading, notebook]);

  useEffect(() => {
    if (!appId || !hasAccess || !email) return;

    getNotebook({ appId }).then((notebook) => {
      setNotebook(notebook);
      fetchNotebookTables({ notebook });
    });
    getRoles({ email }).then(handleGetRolesResponse);
    getConnectors({ appId }).then((connectorsResponse) => {
      handleConnectorsResponse(connectorsResponse);
      setSideNavOpen(true);
    });
    createConversation({ appId }).then(handleCreateConversation);
    getConnectorTypes().then((x) => {
      setConnectorTypes(x);
      // setSelectedPage({
      //   contentType: PageContentType.SELECT_CONNECT_CARD_TYPE,
      // });
    });
    fetchPushConnectors();
  }, [appId, hasAccess, email]);
};

export const useFetchApp: () => void = () => {
  const [authToken] = useAuthToken();
  const [appId, setAppId] = useAppId();
  const fetchAppId = useFetchAppId();

  useEffect(() => {
    if (!authToken) return;
    fetchAppId();
  }, [authToken]);

  useFetchAppData();
};

export const usePageIds: () => any = () => {
  const [notebook, setNotebook] = useNotebook();
  const pages = R.sortBy(R.prop("page-id") as any, notebook?.pages ?? []);
  return pages;
};

export const useReplyConversation: () => (config: {
  content: string;
  chatId?: string;
}) => void = () => {
  const [chatIdFromState, setChatId] = useChatId();
  const [appId, setAppId] = useAppId();
  const addMessage = useAddMessage();
  const [notebook, setNotebook] = useNotebook();
  const [chatLoading, setChatLoading] = useChatLoading();
  const selectMainNote = useSelectMainNote();
  const fetchNotebookTables = useFetchNotebookTables();

  const replyConversation: (config: {
    content: string;
    chatId?: string;
  }) => void = ({ content, chatId: chatIdFromConfig }) => {
    const chatId = chatIdFromConfig ?? chatIdFromState;
    addMessage({ content, role: MessageRole.USER });
    setChatLoading(true);
    replyConversationApi({ content, chatId: chatId! })
      .then((replyResponse) => {
        const reply = replyResponse.reply;
        addMessage({ content: reply, role: MessageRole.ASSISTANT });
        setChatLoading(false);
      })
      .then((response) => {
        getNotebook({ appId: appId! }).then((notebook) => {
          setNotebook(notebook);
          fetchNotebookTables({ notebook });
          selectMainNote({ notebook });
        });
      });
  };
  return replyConversation;
};

export const useChatLoading: () => [
  boolean,
  (chatLoading: boolean) => void
] = () => {
  const chatLoading = useSelector((state: RootState) => state.main.chatLoading);
  const dispatch = useDispatch();
  const setChatLoading = (chatLoading: boolean) => {
    dispatch({ type: ActionType.SET_CHAT_LOADING, chatLoading });
  };
  return [chatLoading, setChatLoading];
};

export const useDeleteNoteTable: () => (config: {
  tableId: string;
}) => void = () => {
  const [notebook, setNotebook] = useNotebook();
  const [appId, setAppId] = useAppId();
  const selectMainNote = useSelectMainNote();

  const deleteNoteTable: (config: { tableId: string }) => void = ({
    tableId,
  }) => {
    deleteTable({ tableId })
      .then((response) => getNotebook({ appId: appId! }))
      .then((notebook) => {
        setNotebook(notebook);
        selectMainNote({ notebook });
      });
  };
  return deleteNoteTable;
};

export const useAuthToken: () => [
  string | undefined,
  (authToken: string) => void
] = () => {
  const authToken = useSelector((state: RootState) => state.main.authToken);
  const dispatch = useDispatch();
  const setAuthToken = (authToken: string) => {
    dispatch({ type: ActionType.SET_AUTH_TOKEN, authToken });
  };
  return [authToken, setAuthToken];
};

export const useFetchAuthToken: () => void = () => {
  const { isAuthenticated, getAccessTokenSilently } = useAuth0();
  const [authToken, setAuthToken] = useAuthToken();
  useEffect(() => {
    if (!isAuthenticated) return;
    getAccessTokenSilently().then(setAuthToken);
  }, [isAuthenticated]);
};

export const useInitialPageLoaded: () => [
  boolean,
  (initialPageLoaded: boolean) => void
] = () => {
  const initialPageLoaded = useSelector(
    (state: RootState) => state.main.initialPageLoaded ?? false
  );
  const dispatch = useDispatch();
  const setInitialPageLoaded = (initialPageLoaded: boolean) => {
    dispatch({ type: ActionType.SET_INITIAL_PAGE_LOADING, initialPageLoaded });
  };
  return [initialPageLoaded, setInitialPageLoaded];
};

export const useSetInitialPage: () => void = () => {
  const { isAuthenticated } = useAuth0();
  const [selectedPage, setSelectedPage] = useSelectedPage();
  const [initialPageLoaded, setInitialPageLoaded] = useInitialPageLoaded();

  useEffect(() => {
    setInitialPageLoaded(true);
    if (isAuthenticated) {
      setSelectedPage({ contentType: PageContentType.LOADING_ACCOUNT });
    } else {
      setSelectedPage({ contentType: PageContentType.NOT_LOGGED_IN });
    }
  }, [initialPageLoaded]);
};
