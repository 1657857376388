import { useAuth0 } from "@auth0/auth0-react";
import AddIcon from "@mui/icons-material/Add";
import DeleteIcon from "@mui/icons-material/Delete";
import {
  Box,
  Button,
  Checkbox,
  IconButton,
  MenuItem,
  Modal,
  Paper,
  Select,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
  useTheme,
} from "@mui/material";
import {
  CardElement,
  Elements,
  useElements,
  useStripe,
} from "@stripe/react-stripe-js";
import { StripeError } from "@stripe/stripe-js";
import * as R from "ramda";
import React, { useEffect, useState } from "react";
import {
  activateSubscription,
  cancelSubscription,
  createSetupIntent,
  createSubscription,
  deletePaymentMethod,
  setDefaultPaymentMethod,
} from "../../api";
import { useAppId, useAppOwner, useApps } from "../../hook";
import { useUserIsOwner } from "../../hook/role";
import {
  useAddWorkspace,
  useAddWorkspaceUser,
  useAllUsers,
  useAllWorkspaces,
  useAppIdToApp,
  useAppsFromAllSources,
  useDeleteWorkspace,
  useDeleteWorkspaceUser,
  useFetchAllUsers,
  useFetchAllWorkspaces,
  useFetchPaymentMethods,
  useFetchSettings,
  useFetchStripeSubscription,
  useFetchWorkspaceUsers,
  useIsAppOwner,
  usePaymentMethods,
  useSetSelectedApp,
  useStripeCustomer,
  useSubscriptionStatus,
  useUserIsAppOwner,
  useWorkspaceUsers,
} from "../../hook/settings";
import { stripePromise } from "../../stripe";
import { NoteBlockContainer, NotePageContainer } from "../note";

import { PaymentMethod } from "../../reducer";

export const WorkspaceTable: React.FC = () => {
  const [apps, setApps] = useApps();
  const [appId, setAppId] = useAppId();
  const setSelectedApp = useSetSelectedApp();
  const { user } = useAuth0();

  return (
    <NoteBlockContainer name="My Workspaces">
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>Workspace</TableCell>
            <TableCell>Selected</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {apps.map((app) => {
            const checked = app.id === appId;
            return (
              <TableRow key={app.id}>
                <TableCell>{app.name}</TableCell>
                <TableCell>
                  <Checkbox
                    onClick={() => {
                      setSelectedApp({ appId: app.id, email: user?.email! });
                    }}
                    disabled={checked}
                    checked={checked}
                  ></Checkbox>
                </TableCell>
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
    </NoteBlockContainer>
  );
};

export const formatDate: (config: { timestamp: number }) => string = ({
  timestamp,
}) => new Date(timestamp * 1000).toLocaleDateString();

export interface WorkspaceUserFormData {
  email: string;
  name: string;
  "app-name"?: string;
}

export const initialWorkspaceUserFormData: WorkspaceUserFormData = {
  email: "",
  name: "",
};

export const AddWorkspaceUserModal: React.FC<{
  setFormData: (setFormData: WorkspaceUserFormData) => any;
  formData: WorkspaceUserFormData;
  modalOpen: boolean;
  setModelOpen: (isOpen: boolean) => any;
  onSubmit: (config: { formData: WorkspaceUserFormData; appId: string }) => any;
  appId: string;
  appSelector?: boolean;
  appTextInput?: boolean;
}> = ({
  setFormData,
  formData,
  modalOpen,
  setModelOpen,
  onSubmit,
  appId,
  appSelector = false,
  appTextInput = false,
}) => {
  const [formErrors, setFormErrors] = useState<string[]>([]);
  const apps = useAppsFromAllSources();
  const appIdToApp = useAppIdToApp();
  const [selectedApp, setSelectedApp] = useState(appIdToApp(appId));
  const appName = selectedApp?.name;

  const validateForm = () => {
    const emailFormErrors = formData["email"] ? [] : ["email"];
    const nameFormErrors = formData["name"] ? [] : ["name"];
    const formErrors = [...emailFormErrors, ...nameFormErrors];
    setFormErrors(formErrors);
    return formErrors.length === 0;
  };

  const setFormField = (key: string, value: string) => {
    setFormData({ ...formData, [key]: value });
  };

  return (
    <Modal open={modalOpen}>
      <Box
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
        }}
      >
        <Paper
          elevation={7}
          sx={{
            padding: "2rem",
            width: "40rem",
            gap: 2,
            display: "flex",
            flexDirection: "column",
          }}
        >
          <Typography variant="h6">Add User To {appName}</Typography>
          <TextField
            value={formData["email"]}
            label="Email"
            placeholder="johndoe@gmail.com"
            error={R.includes("email", formErrors)}
            helperText={
              R.includes("email", formErrors) ? "Required" : undefined
            }
            onChange={(e) => setFormField("email", e.target.value)}
          />
          <TextField
            value={formData["name"]}
            label="Name"
            placeholder="John Doe"
            error={R.includes("name", formErrors)}
            helperText={R.includes("name", formErrors) ? "Required" : undefined}
            onChange={(e) => setFormField("name", e.target.value)}
          />
          {appTextInput && (
            <TextField
              value={formData["app-name"]}
              label="Workspace Name"
              placeholder="My Workspace"
              error={R.includes("app-name", formErrors)}
              helperText={
                R.includes("app-name", formErrors) ? "Required" : undefined
              }
              onChange={(e) => setFormField("name", e.target.value)}
            />
          )}
          {appSelector && (
            <Select value={selectedApp?.id}>
              {apps.map((app) => {
                return (
                  <MenuItem
                    key={app.id}
                    onClick={() => {
                      setSelectedApp(app);
                    }}
                    value={app.id}
                  >
                    {app.name}
                  </MenuItem>
                );
              })}
            </Select>
          )}
          <Box>
            <Button
              onClick={() => {
                if (!validateForm()) return;
                if (!selectedApp) return;
                onSubmit({ formData, appId: selectedApp.id });
                setModelOpen(false);
              }}
            >
              Add
            </Button>
            <Button onClick={() => setModelOpen(false)}>Cancel</Button>
          </Box>
        </Paper>
      </Box>
    </Modal>
  );
};

export const WorkspaceUserTable: React.FC = () => {
  const [appId, setAppId] = useAppId();
  const [workspaceUsers, setWorkspaceUsers] = useWorkspaceUsers();
  const userIsOwner = useUserIsAppOwner();
  const fetchWorkspaceUsers = useFetchWorkspaceUsers();
  const theme = useTheme();
  const addWorkspaceUser = useAddWorkspaceUser();
  const deleteWorkspaceUser = useDeleteWorkspaceUser();
  const [addUserModalOpen, setAddUserModelOpen] = useState(false);
  const [formData, setFormData] = useState<WorkspaceUserFormData>(
    initialWorkspaceUserFormData
  );

  const fetchSettings = useFetchSettings();

  useEffect(() => {
    if (!appId) return;
    setWorkspaceUsers([]);
    fetchWorkspaceUsers({ appId });
  }, [appId]);

  useEffect(() => {
    if (!appId) return;
    fetchSettings();
  }, []);

  return (
    <>
      <AddWorkspaceUserModal
        appId={appId!}
        formData={formData}
        setFormData={setFormData}
        modalOpen={addUserModalOpen}
        setModelOpen={setAddUserModelOpen}
        onSubmit={({ formData: { email, name }, appId }) => {
          addWorkspaceUser({ email, name, appId });
        }}
      />
      <NoteBlockContainer name="Workspace Users">
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Email</TableCell>
              <TableCell>Name</TableCell>
              <TableCell></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {workspaceUsers?.map((workspaceUser) => {
              return (
                <TableRow key={workspaceUser.email}>
                  <TableCell>{workspaceUser.email}</TableCell>
                  <TableCell>{workspaceUser.name}</TableCell>
                  <TableCell>
                    <IconButton
                      onClick={() =>
                        deleteWorkspaceUser({
                          appId: appId!,
                          email: workspaceUser.email,
                        })
                      }
                      disabled={userIsOwner({ email: workspaceUser.email })}
                      sx={{ color: theme.palette.primary.main }}
                    >
                      <DeleteIcon />
                    </IconButton>
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
        <Box sx={{ justifyContent: "end", display: "flex" }}>
          <IconButton
            onClick={() => setAddUserModelOpen(true)}
            sx={{ color: theme.palette.primary.main }}
          >
            <AddIcon />
          </IconButton>
        </Box>
      </NoteBlockContainer>
    </>
  );
};

export interface WorkspaceFormData {
  ownerEmail: string;
  ownerName: string;
  appName: string;
}

export const initialWorkspaceFormData: WorkspaceFormData = {
  ownerEmail: "",
  ownerName: "",
  appName: "",
};

export const AddWorkspaceModal: React.FC<{
  setFormData: (setFormData: WorkspaceFormData) => any;
  formData: WorkspaceFormData;
  modalOpen: boolean;
  setModelOpen: (isOpen: boolean) => any;
  onSubmit: (config: { formData: WorkspaceFormData }) => any;
}> = ({ setFormData, formData, modalOpen, setModelOpen, onSubmit }) => {
  const [formErrors, setFormErrors] = useState<string[]>([]);

  const validateForm = () => {
    const emailFormErrors = formData.ownerEmail ? [] : ["ownerEmail"];
    const appNameFormErrors = formData.appName ? [] : ["appName"];
    const ownerNameFormErrors = formData.ownerName ? [] : ["ownerName"];
    const formErrors = [
      ...emailFormErrors,
      ...appNameFormErrors,
      ...ownerNameFormErrors,
    ];
    setFormErrors(formErrors);
    return formErrors.length === 0;
  };

  const setFormField = (key: string, value: string) => {
    setFormData({ ...formData, [key]: value });
  };

  return (
    <Modal open={modalOpen}>
      <Box
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
        }}
      >
        <Paper
          elevation={7}
          sx={{
            padding: "2rem",
            width: "40rem",
            gap: 2,
            display: "flex",
            flexDirection: "column",
          }}
        >
          <Typography variant="h6">Add New Workspace</Typography>
          <TextField
            value={formData.ownerEmail}
            label="Owner Email"
            placeholder="johndoe@gmail.com"
            error={R.includes("ownerEmail", formErrors)}
            helperText={
              R.includes("ownerEmail", formErrors) ? "Required" : undefined
            }
            onChange={(e) => setFormField("ownerEmail", e.target.value)}
          />
          <TextField
            value={formData.ownerName}
            label="Owner Name"
            placeholder="John Doe"
            error={R.includes("ownerName", formErrors)}
            helperText={
              R.includes("ownerName", formErrors) ? "Required" : undefined
            }
            onChange={(e) => setFormField("ownerName", e.target.value)}
          />
          <TextField
            value={formData.appName}
            label="Workspace Name"
            placeholder="My Workspace"
            error={R.includes("appName", formErrors)}
            helperText={
              R.includes("appName", formErrors) ? "Required" : undefined
            }
            onChange={(e) => setFormField("appName", e.target.value)}
          />
          <Box>
            <Button
              onClick={() => {
                if (!validateForm()) return;
                onSubmit({ formData });
                setModelOpen(false);
              }}
            >
              Add
            </Button>
            <Button onClick={() => setModelOpen(false)}>Cancel</Button>
          </Box>
        </Paper>
      </Box>
    </Modal>
  );
};

export const GlobalAdminWorkspaceTable: React.FC = () => {
  const [allWorkspaces, setAllWorkspaces] = useAllWorkspaces();
  const fetchAllWorkspaces = useFetchAllWorkspaces();
  const theme = useTheme();
  const deleteWorkspace = useDeleteWorkspace();
  const addWorkspace = useAddWorkspace();
  // const [formData, setFormData] = useState<WorkspaceFormData>({
  //   ownerEmail: "andreasv@artios.io",
  //   ownerName: "Andreas",
  //   appName: "Artios Workspace",
  // });
  const [formData, setFormData] = useState(initialWorkspaceFormData);
  const [modalOpen, setModalOpen] = useState(false);

  useEffect(() => {
    if (allWorkspaces.length !== 0) return;
    fetchAllWorkspaces();
  }, []);

  return (
    <>
      <AddWorkspaceModal
        formData={formData}
        setFormData={setFormData}
        modalOpen={modalOpen}
        setModelOpen={setModalOpen}
        onSubmit={({ formData: { ownerEmail, appName, ownerName } }) => {
          addWorkspace({
            ownerEmail,
            appName,
            ownerName,
          });
        }}
      />
      <NoteBlockContainer name="Workspaces">
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Name</TableCell>
              <TableCell></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {allWorkspaces.map((workspace) => {
              return (
                <TableRow key={workspace.name}>
                  <TableCell>{workspace.name}</TableCell>
                  <TableCell>
                    <IconButton
                      onClick={() => {
                        deleteWorkspace({ appId: workspace.id });
                      }}
                      sx={{ color: theme.palette.primary.main }}
                    >
                      <DeleteIcon />
                    </IconButton>
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
        <Box sx={{ justifyContent: "end", display: "flex" }}>
          <IconButton
            onClick={() => setModalOpen(true)}
            sx={{ color: theme.palette.primary.main }}
          >
            <AddIcon />
          </IconButton>
        </Box>
      </NoteBlockContainer>
    </>
  );
};

export const GlobalAdminWorkspaceUserTable: React.FC = () => {
  const [appUsers, setAppUsers] = useAllUsers();
  const fetchAppUsers = useFetchAllUsers();
  const theme = useTheme();
  const apps = useAppsFromAllSources();
  const appIdToApp = useAppIdToApp();
  const [addUserModalOpen, setAddUserModelOpen] = useState(false);
  const [formData, setFormData] = useState<WorkspaceUserFormData>(
    initialWorkspaceUserFormData
  );
  const [appId, setAppId] = useState(apps?.[0]?.id);
  const addWorkspaceUser = useAddWorkspaceUser();
  const deleteWorkspaceUser = useDeleteWorkspaceUser();
  const fetchAllWorkspaces = useFetchAllWorkspaces();
  const userIsOwner = useUserIsAppOwner();

  useEffect(() => {
    if (apps.length !== 0) return;

    fetchAllWorkspaces();
  });

  useEffect(() => {
    if (appUsers.length !== 0) return;

    fetchAppUsers();
  }, [appUsers]);

  return (
    <>
      <AddWorkspaceUserModal
        appId={appId!}
        formData={formData}
        setFormData={setFormData}
        modalOpen={addUserModalOpen}
        setModelOpen={setAddUserModelOpen}
        onSubmit={({ formData: { email, name }, appId }) => {
          addWorkspaceUser({ email, name, appId });
        }}
        appSelector={true}
      />
      <NoteBlockContainer name="All Workspace Users">
        <TableContainer>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Email</TableCell>
                <TableCell>Workspace</TableCell>
                <TableCell>Name</TableCell>
                <TableCell></TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {appUsers.map((appUser, i) => {
                const app = appIdToApp(appUser["app-id"]);

                return (
                  <TableRow key={i}>
                    <TableCell>{appUser.email}</TableCell>
                    <TableCell>{app?.name}</TableCell>
                    <TableCell>{appUser.name}</TableCell>
                    <TableCell>
                      <IconButton
                        onClick={() =>
                          deleteWorkspaceUser({
                            appId: appUser["app-id"],
                            email: appUser.email,
                          })
                        }
                        disabled={userIsOwner({
                          email: appUser.email,
                          appId: appUser["app-id"],
                        })}
                        sx={{ color: theme.palette.primary.main }}
                      >
                        <DeleteIcon />
                      </IconButton>
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
          <Box sx={{ justifyContent: "end", display: "flex" }}>
            <IconButton
              onClick={() => {
                setAddUserModelOpen(true);
              }}
              sx={{ color: theme.palette.primary.main }}
            >
              <AddIcon />
            </IconButton>
          </Box>
        </TableContainer>
      </NoteBlockContainer>
    </>
  );
};

export const AddPaymentMethodModal: React.FC<{
  modalOpen: boolean;
  setModalOpen: (modalOpen: boolean) => any;
}> = ({ modalOpen, setModalOpen }) => {
  const theme = useTheme();
  const elements = useElements()!;
  const stripe = useStripe()!;
  const [error, setError] = useState<StripeError | undefined>(undefined);
  const { user } = useAuth0();
  const email = user!.email!;
  const fetchPaymentMethods = useFetchPaymentMethods();
  const fetchSubscription = useFetchStripeSubscription();

  const onSubmit = async () => {
    const cardElement = elements.getElement(CardElement)!;

    const { "client-secret": clientSecret } = await createSetupIntent({
      email,
    });

    const { setupIntent, error } = await stripe.confirmCardSetup(clientSecret, {
      payment_method: {
        card: cardElement,
        billing_details: {
          email,
        },
      },
    });

    if (error) {
      setError(error);
      return;
    } else {
      setDefaultPaymentMethod({
        email,
        paymentMethod: setupIntent.payment_method as string,
      }).then(() => {
        fetchPaymentMethods();
        fetchSubscription();
      });
      setError(undefined);
      setModalOpen(false);
    }
  };

  return (
    <Modal open={modalOpen}>
      <Box
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
        }}
      >
        <Paper
          elevation={7}
          sx={{
            padding: "2rem",
            width: "40rem",
            gap: 2,
            display: "flex",
            flexDirection: "column",
          }}
        >
          <Typography variant="h6">Add Payment Method</Typography>
          <Paper
            elevation={9}
            sx={{
              padding: "1rem",
              backgroundColor: theme.palette.grey["300"],
            }}
          >
            <CardElement />
          </Paper>
          {error && (
            <Typography color={theme.palette.error.dark}>
              {error.message}
            </Typography>
          )}
          <Box>
            <Button
              onClick={() => {
                onSubmit();
              }}
            >
              Add
            </Button>
            <Button onClick={() => setModalOpen(false)}>Cancel</Button>
          </Box>
        </Paper>
      </Box>
    </Modal>
  );
};

export const WorkspaceAdminPaymentSettings: React.FC = () => {
  const fetchPaymentMethods = useFetchPaymentMethods();
  const [paymentMethods, setPaymentMethods] = usePaymentMethods();
  const theme = useTheme();
  const [modalOpen, setModalOpen] = useState(false);
  const isDefaultMethod = (method: PaymentMethod) =>
    method["stripe-id"] === (paymentMethods as any)?.["default-payment-method"];
  const { user } = useAuth0();
  const email = user?.email!;
  const fetchSubscription = useFetchStripeSubscription();

  const onSetPaymentMethod = (id: string) => {
    setDefaultPaymentMethod({ paymentMethod: id, email }).then((response) => {
      fetchPaymentMethods();
      fetchSubscription();
    });
  };

  const onDeletePaymentMethod = (id: string) => {
    deletePaymentMethod({ paymentMethod: id }).then((response) => {
      fetchPaymentMethods();
      fetchSubscription();
    });
  };

  return (
    <>
      <Elements stripe={stripePromise}>
        <AddPaymentMethodModal
          modalOpen={modalOpen}
          setModalOpen={setModalOpen}
        />
      </Elements>
      <NoteBlockContainer name="Payment Methods">
        <TableContainer>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Created</TableCell>
                <TableCell>Brand</TableCell>
                <TableCell>Last 4</TableCell>
                <TableCell>Expiration</TableCell>
                <TableCell>Default</TableCell>
                <TableCell></TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {paymentMethods?.methods?.map((paymentMethod, i) => {
                return (
                  <TableRow key={i}>
                    <TableCell>
                      {formatDate({ timestamp: paymentMethod.created })}
                    </TableCell>
                    <TableCell>{paymentMethod.brand}</TableCell>
                    <TableCell>{paymentMethod.last4}</TableCell>
                    <TableCell>{`${paymentMethod["exp-month"]}-${paymentMethod["exp-year"]}`}</TableCell>
                    <TableCell>
                      <Checkbox
                        checked={isDefaultMethod(paymentMethod)}
                        disabled={isDefaultMethod(paymentMethod)}
                        sx={{ color: theme.palette.primary.main }}
                        onClick={() =>
                          onSetPaymentMethod(paymentMethod["stripe-id"])
                        }
                      />
                    </TableCell>
                    <TableCell>
                      <IconButton
                        disabled={isDefaultMethod(paymentMethod)}
                        sx={{ color: theme.palette.primary.main }}
                        onClick={() =>
                          onDeletePaymentMethod(paymentMethod["stripe-id"])
                        }
                      >
                        <DeleteIcon />
                      </IconButton>
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
          <Box sx={{ justifyContent: "end", display: "flex" }}>
            <IconButton
              onClick={() => {
                setModalOpen(true);
              }}
              sx={{ color: theme.palette.primary.main }}
            >
              <AddIcon />
            </IconButton>
          </Box>
        </TableContainer>
      </NoteBlockContainer>
    </>
  );
};

export const WorkspaceSubscriptionSettings: React.FC = () => {
  const { user } = useAuth0();
  const email = user?.email!;
  const [appOwner] = useAppOwner();
  const {
    periodStart,
    periodEnd,
    renewing,
    canPayImmediately,
    canEnableRenewal,
    addPaymentMethodPrompt,
    canCancel,
    trialing,
    hasAccess,
    cancelAtPeriodEnd,
    active,
    canceled,
    workspaceOwnerPrompt,
  } = useSubscriptionStatus();
  const fetchSubscription = useFetchStripeSubscription();

  const onEnableRenewal = () => {
    activateSubscription({ email }).then(() => {
      fetchSubscription();
    });
  };

  const onRenew = () => {
    createSubscription({ email }).then(() => {
      fetchSubscription();
    });
  };

  const onCancel = () => {
    cancelSubscription({ email }).then((response) => {
      fetchSubscription();
    });
  };

  return (
    <>
      <NoteBlockContainer name="Subscription Status">
        <Box>
          {trialing && <Typography>Free Trial</Typography>}
          {active && <Typography>Active</Typography>}
          {renewing && <Typography>Renewing At Period End</Typography>}
          {cancelAtPeriodEnd && (
            <Typography>Canceling At Period End</Typography>
          )}
          {addPaymentMethodPrompt && (
            <Typography>Add a Payment Method to avoid cancelation</Typography>
          )}
          {canceled && <Typography>Inactive</Typography>}
          {hasAccess && (
            <>
              <Typography>
                <strong>Period Start</strong>{" "}
                {formatDate({ timestamp: periodStart })}
              </Typography>
              <Typography>
                <strong>Period End</strong>{" "}
                {formatDate({ timestamp: periodEnd })}
              </Typography>
            </>
          )}
          {canCancel && (
            <Button onClick={() => onCancel()}>Cancel At Period End</Button>
          )}
          {canEnableRenewal && (
            <Button onClick={() => onEnableRenewal()}>
              Renew At Period End
            </Button>
          )}
          {canPayImmediately && (
            <Button onClick={() => onRenew()}>
              Renew Canceled Subscription
            </Button>
          )}
          {workspaceOwnerPrompt && (
            <Typography>
              Contact workspace admin {appOwner} to change subscription.
            </Typography>
          )}
        </Box>
      </NoteBlockContainer>
    </>
  );
};

export const SettingsPage: React.FC = () => {
  const isOwner = useUserIsOwner();
  const { hasAccess } = useSubscriptionStatus();
  const isAppOwner = useIsAppOwner();

  return (
    <Stack gap={3}>
      {true && (
        <NotePageContainer pageName="Workspace Settings">
          <WorkspaceTable />
          <WorkspaceUserTable />
          <></>
        </NotePageContainer>
      )}
      <NotePageContainer pageName="Subscription Settings">
        {isAppOwner && <WorkspaceAdminPaymentSettings />}
        <WorkspaceSubscriptionSettings />
        <></>
      </NotePageContainer>
      {isOwner && (
        <NotePageContainer pageName="Global Admin Settings">
          <GlobalAdminWorkspaceTable />
          <GlobalAdminWorkspaceUserTable />
          <></>
        </NotePageContainer>
      )}
      <></>
    </Stack>
  );
};
