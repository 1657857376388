import { Auth0Provider, useAuth0 } from "@auth0/auth0-react";
import { Box, CssBaseline } from "@mui/material";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import * as Sentry from "@sentry/react";
import React from "react";
import "./App.css";
import { AppBarSection } from "./components/appBar";
import { ChatDrawer } from "./components/chatDrawer";
import { AccountLoadingPage } from "./components/page/accountLoading";
import { AddConnectCardPage } from "./components/page/addConnectCard";
import { NoWorkspacePage } from "./components/page/noWorkspace";
import { NotLoggedInPage } from "./components/page/notLoggedIn";
import { NotePage } from "./components/page/note";
import { PullConnectorPage } from "./components/page/pullConnector/pullConnector";
import { PullConnectorTablePage } from "./components/page/pullConnector/pullConnectorTable";
import { PushConnectorEditPage } from "./components/page/pushConnector/edit";
import { PushConnectorViewPage } from "./components/page/pushConnector/view";
import { SelectConnectCardPage } from "./components/page/selectConnectorType";
import { SettingsPage } from "./components/page/settings";
import { SideNav } from "./components/sideNav";
import {
  useFetchApp,
  useFetchAuthToken,
  useSelectedPage,
  useSetInitialPage,
  useSideNavOpen,
} from "./hook";
import { useDrawerWidth } from "./hook/layout";
import { useFetchSelectedConnectCard } from "./hook/pullConnector";
import { useInitCustomer } from "./hook/settings";
import { PageContentType } from "./reducer";

if (!process.env.REACT_APP_SENTRY_DSN) {
  throw new Error("Could Not Find REACT_APP_SENTRY_DSN environment variable.");
}
if (!process.env.REACT_APP_SENTRY_ENV) {
  throw new Error("Could Not Find REACT_APP_SENTRY_ENV environment variable.");
}
if (!process.env.REACT_APP_SENTRY_RELEASE) {
  console.warn(
    "REACT_APP_SENTRY_RELEASE environment variable not set. Using default."
  );
}

const sentryDsn = process.env.REACT_APP_SENTRY_DSN;
const sentryEnv = process.env.REACT_APP_SENTRY_ENV;
const sentryRelease =
  process.env.REACT_APP_SENTRY_RELEASE || "omega-ui@dev-default";

Sentry.init({
  dsn: sentryDsn,
  environment: sentryEnv,
  release: sentryRelease,
  integrations: [
    Sentry.browserTracingIntegration(),
    Sentry.replayIntegration({
      maskAllText: false,
      blockAllMedia: false,
    }),
  ],
  tracesSampleRate: 1.0,
  replaysSessionSampleRate: 0.1,
  replaysOnErrorSampleRate: 1.0,
});

const theme = createTheme({
  palette: {
    mode: "dark",
    primary: {
      main: "#e84bfa",
    },
    secondary: {
      main: "#351c75",
    },
  },
});

const PageContentBox: React.FC<{
  children: React.ReactNode;
  drawerWidth: string;
}> = ({ children, drawerWidth }) => {
  const [sideNavOpen] = useSideNavOpen();
  const { isAuthenticated } = useAuth0();
  return (
    <Box
      position="absolute"
      sx={{
        padding: "3rem",
        paddingTop: 12,
        paddingBottom: "40rem",
        transition: (theme) =>
          theme.transitions.create(["width", "margin"], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
          }),
        width:
          isAuthenticated && sideNavOpen
            ? `calc(100% - ${drawerWidth})`
            : "100%",
        marginLeft: isAuthenticated && sideNavOpen ? `${drawerWidth}` : 0,
        overflow: "scroll",
      }}
    >
      {children}
    </Box>
  );
};

const PageContent: React.FC = () => {
  const [selectedPage, setSelectedPage] = useSelectedPage();
  const pageContentType = selectedPage.contentType;

  switch (pageContentType) {
    case PageContentType.LOADING_ACCOUNT:
      return <AccountLoadingPage />;
    case PageContentType.NOT_LOGGED_IN:
      return <NotLoggedInPage />;
    case PageContentType.NO_WORKSPACE:
      return <NoWorkspacePage />;
    case PageContentType.SELECT_CONNECT_CARD_TYPE:
      return <SelectConnectCardPage />;
    case PageContentType.NOTEBOOK:
      return <NotePage />;
    case PageContentType.ADD_CONNECT_CARD:
      return <AddConnectCardPage />;
    case PageContentType.PULL_CONNECTOR_TABLE:
      return <PullConnectorTablePage />;
    case PageContentType.VIEW_PUSH_CONNECTOR:
      return <PushConnectorViewPage />;
    case PageContentType.EDIT_PUSH_CONNECTOR:
      return <PushConnectorEditPage />;
    case PageContentType.PULL_CONNECTOR:
      return <PullConnectorPage />;
    case PageContentType.SETTINGS:
      return <SettingsPage />;
  }
};

export const getUrlSearch = () => {
  const params = new URLSearchParams(window.location.search);
  const paramMap = {} as any;
  params.forEach((value, key) => {
    paramMap[key] = value;
  });
  return paramMap;
};

const App: React.FC = () => {
  const drawerWidth = useDrawerWidth();
  useFetchApp();
  useFetchSelectedConnectCard();
  useFetchAuthToken();
  useSetInitialPage();
  useInitCustomer();

  return (
    <>
      <CssBaseline />
      <Box sx={{ display: "flex", flexDirection: "row" }}>
        <SideNav drawerWidth={drawerWidth} />
        <AppBarSection drawerWidth={drawerWidth} />
        <PageContentBox drawerWidth={drawerWidth}>
          <PageContent />
        </PageContentBox>
        <ChatDrawer />
      </Box>
    </>
  );
};

const AppProvider: React.FC = () => {
  return (
    <>
      <Auth0Provider
        domain="dev-a00fvs6crrle25gi.us.auth0.com"
        clientId="Lg6N9c6nUxk8MZV2Dfr0kzAyGgv9BRRs"
        authorizationParams={{
          redirect_uri: window.location.origin,
        }}
        cacheLocation="localstorage"
      >
        <ThemeProvider theme={theme}>
          <App />
        </ThemeProvider>
      </Auth0Provider>
    </>
  );
};

export default AppProvider;
