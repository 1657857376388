import { debounce } from "lodash";
import * as R from "ramda";
import { useCallback, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setPageData } from "../api";
import { useAppId } from "../hook";
import { ActionType, AppDispatch, RootState } from "../reducer";
import {
  NoteBlock,
  NoteEdits,
  NotePage,
  Notebook,
  NotebookPage,
  PageBlock,
  TableBlock,
  isNoteBlock,
  isTableBlock,
} from "../types/note";
import { useFetchTableSpecs, useFetchTables } from "./table";

export const useSelectMainNote: () => (config?: {
  notebook?: Notebook;
}) => void = () => {
  const [notebookState, setNotebook] = useNotebook();
  const [selectedNote, setSelectedNote] = useSelectedNote();

  return (config = {}) => {
    const { notebook: notebookParam } = config;
    const notebook = (notebookParam ?? notebookState)!;
    const note = notebook.pages[0];
    setSelectedNote(note);
  };
};

export const getTableIds: (notebook: Notebook) => string[] = (notebook) => {
  return notebook.pages
    .flatMap((page) => page["page-data"].blocks)
    .filter((block) => isTableBlock(block))
    .map((block) => block as TableBlock)
    .map((block) => block["table-id"]);
};

export const useFetchNotebookTables: () => (config?: {
  notebook: Notebook;
}) => Promise<void> = () => {
  const [notebookFromState, setNotebook] = useNotebook();
  const fetchTableSpecs = useFetchTableSpecs();
  const fetchTables = useFetchTables();

  return async (config) => {
    const notebookFromParam = config?.notebook;
    const notebook = (notebookFromParam ?? notebookFromState)!;
    const tableIds = getTableIds(notebook);
    const tableSpecs = await fetchTableSpecs({ tableIds });
    fetchTables({ tableSpecs });
  };
};

export const useNotebook: () => [
  Notebook | undefined,
  (notebook: Notebook) => void
] = () => {
  const notebook = useSelector((state: RootState) => state.main.notebook);
  const dispatch = useDispatch<AppDispatch>();
  const setNotebook = (notebook: Notebook) => {
    dispatch({ type: ActionType.SET_NOTEBOOK, notebook });
  };
  return [notebook, setNotebook];
};

export const useNotebookTableIds: () => string[] = () => {
  const [notebook, setNotebook] = useNotebook();
  const tableIds = R.chain(
    R.compose(
      R.map(R.prop("table-id") as any),
      R.path(["page-data", "blocks"]) as any
    ),
    notebook?.pages ?? []
  );
  return tableIds as string[];
};

export const useSelectedNote: () => [
  NotebookPage | undefined,
  (note: NotebookPage) => void
] = () => {
  const selectedNote = useSelector(
    (state: RootState) => state.main.selectedNote
  );
  const dispatch = useDispatch<AppDispatch>();
  const setSelectedNote = (selectedNote: NotebookPage) =>
    dispatch({ type: ActionType.SET_SELECTED_NOTE, selectedNote });
  return [selectedNote, setSelectedNote];
};

export const useInsertBlock: () => (config: {
  blockIndex: number;
}) => void = () => {
  const [notebook, setNotebook] = useNotebook();
  const [_selectedNote] = useSelectedNote();
  const pageId = _selectedNote!["page-id"];
  const flushNoteEdits = useFlushNoteEdits();
  const [_appId] = useAppId();
  const appId = _appId!;
  const [noteEdits, setNoteEdits] = useNoteEdits();

  return ({ blockIndex }) => {
    const notebook = flushNoteEdits({ writeBackend: false, writeState: false });
    const pageIndex = notebook!.pages.findIndex(
      (page) => page["page-id"] === pageId
    );
    const selectedNote = notebook.pages[pageIndex];
    const pageData = selectedNote["page-data"];
    const oldBlocks = pageData.blocks!;
    const newInsert: NoteBlock = {
      html: "",
    };
    const newBlocks = R.insert(blockIndex + 1, newInsert, oldBlocks);
    const newPageData: NotePage = { ...pageData, blocks: newBlocks };
    const newPage: NotebookPage = { ...selectedNote, "page-data": newPageData };
    const pages = notebook!.pages!;
    const newPages = R.update(pageIndex, newPage, pages);
    const newNotebook = { ...notebook, pages: newPages };
    console.log(JSON.stringify(newBlocks, null, 4));
    setNotebook(newNotebook);
    setNoteEdits({});
    setPageData({ appId, pageId, pageData: newPageData });
  };
};

export const useDeleteBlockIndex: () => (config: {
  blockIndex: number;
}) => void = () => {
  const [_notebook, setNotebook] = useNotebook();
  const flushNoteEdits = useFlushNoteEdits();
  const [_selectedNote] = useSelectedNote();
  const [noteEdits, setNoteEdits] = useNoteEdits();
  const pageId = _selectedNote!["page-id"];
  const [_appId] = useAppId();
  const appId = _appId!;

  return ({ blockIndex }) => {
    const notebook = flushNoteEdits({ writeBackend: false, writeState: false });
    const pageIndex = notebook!.pages.findIndex(
      (page) => page["page-id"] === pageId
    );
    const selectedNote = notebook.pages[pageIndex];
    const pageData = selectedNote["page-data"]!;
    const blocks = pageData.blocks;
    const newBlocks = R.remove(blockIndex, 1, blocks);
    const newPageData: NotePage = { ...pageData, blocks: newBlocks };
    const newPage: NotebookPage = {
      ...selectedNote,
      "page-data": newPageData,
    };
    const newPages = R.update(pageIndex, newPage, notebook!.pages);
    const newNotebook: Notebook = { ...notebook, pages: newPages };
    console.log(JSON.stringify(newBlocks, null, 4));
    setNotebook(newNotebook);
    setNoteEdits({});
    setPageData({ pageId, appId, pageData: newPageData });
  };
};

export const useFlushNoteEdits: () => (config?: {
  writeState?: boolean;
  writeBackend?: boolean;
  noteEdits?: NoteEdits;
}) => Notebook = () => {
  const [noteEditsFromState, setNoteEdits] = useNoteEdits();
  const [notebook, setNotebook] = useNotebook();
  const [_selectedNote] = useSelectedNote();
  const selectedNote = _selectedNote!;
  const pageId = selectedNote!["page-id"];
  const [_appId] = useAppId();
  const appId = _appId!;

  return (config = { writeState: true, writeBackend: true }) => {
    const writeState =
      config.writeState === undefined ? true : config.writeState;
    const writeBackend =
      config.writeBackend === undefined ? true : config.writeBackend;
    const noteEdits = (config.noteEdits ?? noteEditsFromState) as NoteEdits;
    const pageData = selectedNote["page-data"];
    const blocks = pageData.blocks;
    const newBlocks = blocks.map((block, i) => {
      if (!isNoteBlock(block)) return block;
      const editHtml = noteEdits[i];
      const newHtml = editHtml ?? block.html;
      return { html: newHtml } as PageBlock;
    });
    const newPageData: NotePage = { ...pageData, blocks: newBlocks };
    const newNotePage: NotebookPage = {
      ...selectedNote,
      "page-data": newPageData,
    };
    const pageIndex = notebook!.pages.findIndex(
      (page) => page["page-id"] === pageId
    );
    const newPages = R.update(pageIndex, newNotePage, notebook!.pages);
    const newNotebook: Notebook = { ...notebook, pages: newPages };
    if (writeState) {
      setNotebook(newNotebook);
      setNoteEdits({});
    }
    if (writeBackend) {
      setPageData({ appId, pageId, pageData: newPageData });
    }
    return newNotebook;
  };
};

export const useNoteEdits: () => [
  NoteEdits,
  (noteEdits: NoteEdits) => void
] = () => {
  const noteEdits = useSelector((state: RootState) => state.main.noteEdits);
  const dispatch = useDispatch<AppDispatch>();
  const setNoteEdits = (noteEdits: NoteEdits) => {
    dispatch({ type: ActionType.SET_NOTE_EDITS, noteEdits });
  };
  return [noteEdits, setNoteEdits];
};

export const useNoteEdit: (
  index: number
) => [string, (noteEdit: string) => void] = (index) => {
  const [noteEdits, setNoteEdits] = useNoteEdits();
  const noteEdit = noteEdits[index];
  const flushNoteEdits = useFlushNoteEdits();
  const [notebook, setNotebook] = useNotebook();

  const flushCallback = useCallback(
    debounce((noteEdits: NoteEdits) => {
      flushNoteEdits({ noteEdits, writeState: false });
    }, 1000),
    [notebook]
  );

  useEffect(() => {
    return () => {
      flushCallback.cancel();
    };
  }, [flushCallback]);

  const setNoteEdit = (noteEdit: string) => {
    const newNoteEdits = { ...noteEdits, [index]: noteEdit };
    setNoteEdits(newNoteEdits);
    flushCallback(newNoteEdits);
  };

  return [noteEdit, setNoteEdit];
};

export const useSwapNoteBlocks: () => (config: {
  firstIndex: number;
  secondIndex: number;
}) => void = () => {
  const [notebook, setNotebook] = useNotebook();
  const [_selectedNote] = useSelectedNote();
  const pageId = _selectedNote!["page-id"];
  const flushNoteEdits = useFlushNoteEdits();
  const [_appId] = useAppId();
  const appId = _appId!;
  const [noteEdits, setNoteEdits] = useNoteEdits();

  return ({ firstIndex, secondIndex }) => {
    const notebook = flushNoteEdits({ writeBackend: false, writeState: false });
    const pageIndex = notebook!.pages.findIndex(
      (page) => page["page-id"] === pageId
    );
    const selectedNote = notebook.pages[pageIndex];
    const pageData = selectedNote["page-data"];
    const oldBlocks = pageData.blocks!;
    const firstVal = oldBlocks[firstIndex];
    const secondVal = oldBlocks[secondIndex];
    const newBlocks = R.pipe(
      R.update(secondIndex, firstVal),
      R.update(firstIndex, secondVal)
    )(oldBlocks);
    const newPageData: NotePage = { ...pageData, blocks: newBlocks };
    const newPage: NotebookPage = { ...selectedNote, "page-data": newPageData };
    const pages = notebook!.pages!;
    const newPages = R.update(pageIndex, newPage, pages);
    const newNotebook = { ...notebook, pages: newPages };
    setNotebook(newNotebook);
    setNoteEdits({});
    setPageData({ appId, pageId, pageData: newPageData });
  };
};
