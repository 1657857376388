import { EDNVal } from "edn-data";

export interface NoteEdits {
  [index: number]: string;
}

export interface NoteBlock {
  html: string;
}

export interface TableBlock {
  "table-id": string;
}

export type PageBlock = NoteBlock | TableBlock;

export type NotePage = {
  name: string;
  blocks: PageBlock[];
};

export interface NotebookPage {
  "page-data": NotePage;
  "page-id": string;
}

export interface Notebook {
  pages: NotebookPage[];
}

export function isTableBlock(block: PageBlock): block is TableBlock {
  return (block as TableBlock)["table-id"] !== undefined;
}

export function isNoteBlock(block: PageBlock): block is NoteBlock {
  return (block as NoteBlock).html !== undefined;
}

export const pageBlockToEdnVal: (block: PageBlock) => EDNVal = (block) => {
  if (isTableBlock(block)) {
    return { map: [["table-id", block["table-id"]]] };
  }
  if (isNoteBlock(block)) {
    return { map: [["html", block.html]] };
  }
  throw new Error("Page block not recognized.");
};
