import * as R from "ramda";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  createConnectCard,
  deletePullConnector as deletePullConnectorApi,
  getConnectorTables,
  getConnectors,
  getTable,
} from "../api";
import { useAppId, useSelectedPage } from "../hook";
import {
  ActionType,
  AppDispatch,
  PageContentType,
  RootState,
} from "../reducer";
import {
  ConnectCard,
  Connector,
  ConnectorStatus,
  ConnectorStatusMap,
  ConnectorTableResponse,
  ConnectorType,
} from "../types/pullConnector";
import { TableMap } from "../types/table";
import { useAddTables, useTables } from "./table";

export const useAssociateConnectorTables: () => (
  connectorTables: any
) => void = () => {
  const setSelectedPullConnector = useSetSelectedPullConnectorTable();
  const dispatch = useDispatch();
  const associateConnectorTables = (connectorTables: any) => {
    dispatch({ type: ActionType.ASSOCIATE_CONNECTOR_TABLES, connectorTables });
    // const connectorId = Object.keys(connectorTables)[0];
    // const tableId = connectorTables[connectorId][0]["file-id"];
    // setSelectedPullConnector({ connectorId, tableId });
  };
  return associateConnectorTables;
};

export const useConnectorStatusMap: () => [
  ConnectorStatusMap,
  (connectorStatusMap: ConnectorStatusMap) => void
] = () => {
  const dispatch = useDispatch();
  const connectorStatusMap = useSelector(
    (state: RootState) => state.main.connectorStatusMap
  );
  const setConnectorStatusMap = (connectorStatusMap: ConnectorStatusMap) => {
    dispatch({ type: ActionType.SET_CONNECTOR_STATUS_MAP, connectorStatusMap });
  };
  return [connectorStatusMap, setConnectorStatusMap];
};

export const useGetConnectorStatus: () => (
  connectorId: string
) => ConnectorStatus | undefined = () => {
  const [connectorStatusMap, setConnectorStatusMap] = useConnectorStatusMap();
  return (connectorId: string) => {
    return connectorStatusMap[connectorId];
  };
};

export const useHandleConnectorsResponse: () => (
  connectorsResponse: any
) => void = () => {
  const [appId, setAppId] = useAppId();
  const associateConnectorTables = useAssociateConnectorTables();
  const addTables = useAddTables();
  const [connectors, setConnectors] = useConnectors();
  const [connectorStatusMap, setConnectorStatusMap] = useConnectorStatusMap();
  const [selectedPullConnector, setSelectedPullConnector] =
    useSelectedPullConnector();

  const fetchConnectorTables: (config: {
    connectorIds: string[];
    appId: string;
  }) => Promise<ConnectorTableResponse[]> = ({ appId, connectorIds }) => {
    return Promise.all(
      R.map(
        (connectorId: string) => getConnectorTables({ appId, connectorId }),
        connectorIds
      )
    );
  };

  const handleConnectorsResponse = (connectorsResponse: any) => {
    const cards = R.sortBy(
      R.path(["card", "schema"]) as any,
      R.prop("cards", connectorsResponse)
    ) as any[];
    const connectorIds = R.map(R.prop("connection-id"), cards);
    fetchConnectorTables({ appId: appId!, connectorIds }).then((responses) => {
      const connectorEntries = R.map((response) => {
        const conId = response["connector-id"];
        const tables = response.tables;
        return [conId, tables];
      }, responses);
      const connectorStatusMap = Object.fromEntries(
        responses.map(({ "connector-id": connectorId, status }) => [
          connectorId,
          status,
        ])
      );
      setConnectorStatusMap(connectorStatusMap);
      const connectorData = Object.fromEntries(connectorEntries);
      associateConnectorTables(connectorData);
      const tables = R.chain(R.prop("tables"), responses);
      addTables(tables);
      // setSelectedPullConnector(responses[0]["connector-id"]);
      // const tablesMap = Object.fromEntries(
      //   tables.map((table) => [table["table-id"], table])
      // );
      // setSelectedPullConnector({
      //   connectorId: "breeder_officiating",
      //   tableId: "6c27f5e3-e747-40c8-bc18-7a29b4ecd805",
      //   tables: tablesMap,
      // });
    });
    setConnectors(cards);
  };
  return handleConnectorsResponse;
};

export const useConnectorTables: (connectorId: string) => any[] = (
  connectorId
) => {
  const tables = useSelector(
    (state: RootState) => state.main.connectorTables?.[connectorId] ?? []
  );
  return tables;
};

export const useSetSelectedPullConnectorTable: () => (config: {
  connectorId: string;
  tableId: string;
  tables?: TableMap;
}) => void = () => {
  const tablesFromHook = useTables();
  const [selectedPage, setSelectedPage] = useSelectedPage();
  const [appId, setAppId] = useAppId();
  const addTables = useAddTables();
  const setSelectedPullConnectorTable: (config: {
    connectorId: string;
    tableId: string;
    tables?: TableMap;
  }) => void = ({ connectorId, tableId, tables: tablesFromConfig }) => {
    const tables = tablesFromConfig ?? tablesFromHook;
    const table = tables[tableId];
    if (!table.rows) {
      getTable({ appId: appId!, tableId: table["table-id"] }).then((table) => {
        addTables([table]);
      });
    }
    setSelectedPage({
      contentType: PageContentType.PULL_CONNECTOR_TABLE,
      page: { connectorId, tableId },
    });
  };
  return setSelectedPullConnectorTable;
};

export const useSelectedPullConnector: () => [
  string | undefined,
  (connectorId: string) => void
] = () => {
  const [selectedPage, setSelectedPage] = useSelectedPage();
  const dispatch = useDispatch();
  const selectedPullConnector = useSelector(
    (state: RootState) => state.main.selectedPullConnector
  );

  const setSelectedPullConnector = (selectedPullConnector: string) => {
    dispatch({
      type: ActionType.SET_SELECTED_PULL_CONNECTOR,
      selectedPullConnector,
    });
    setSelectedPage({ contentType: PageContentType.PULL_CONNECTOR });
  };

  return [selectedPullConnector, setSelectedPullConnector];
};

export const useNavigateTable: () => (tableId: string) => void = () => {
  const connectorTables = useSelector(
    (state: RootState) => state.main.connectorTables
  );
  const setSelectedPullConnector = useSetSelectedPullConnectorTable();

  return (tableId: string) => {
    const tableMatches = (conTable: any) => conTable["table-id"] === tableId;
    const findTableInConEntry = (conEntry: any[]) => {
      const [connectorId, conTables] = conEntry;
      const foundConTable = R.find(tableMatches, conTables);
      return foundConTable && [connectorId, foundConTable];
    };
    const findEntry = R.compose(
      R.head,
      R.filter((x: any) => x),
      R.map(findTableInConEntry)
    );
    const foundEntry: any = findEntry(Object.entries(connectorTables));
    if (foundEntry) {
      const [foundConId, foundConTable] = foundEntry;
      setSelectedPullConnector({
        connectorId: foundConId,
        tableId,
      });
    } else {
      document.getElementById(tableId)?.scrollIntoView();
    }
  };
};

export const useDeletePullConnector: () => (config: {
  connectorId: string;
}) => void = () => {
  const [appId, setAppId] = useAppId();
  const handleConnectorsResponse = useHandleConnectorsResponse();

  const deletePullConnector: (config: { connectorId: string }) => void = ({
    connectorId,
  }) => {
    deletePullConnectorApi({ appId: appId!, connectorId }).then(() => {
      getConnectors({ appId: appId! }).then((connectorsResponse) => {
        handleConnectorsResponse(connectorsResponse);
      });
    });
  };

  return deletePullConnector;
};

export const useConnectors: () => [
  Connector[],
  (connectors: Connector[]) => void
] = () => {
  const connectors = useSelector((state: RootState) => state.main.connectors);
  const dispatch = useDispatch<AppDispatch>();
  const setConnectors = (connectors: Connector[]) =>
    dispatch({ type: ActionType.SET_CONNECTORS, connectors });
  return [connectors, setConnectors];
};

export const useGetConnector: () => (
  connectorId: string
) => Connector | undefined = () => {
  const [connectors, setConnectors] = useConnectors();
  return (connectorId: string) => {
    return connectors.find(
      (connector) => connector["connection-id"] === connectorId
    );
  };
};

export const useConnectorTypes: () => [
  ConnectorType[],
  (connectorTypes: ConnectorType[]) => void
] = () => {
  const connectorTypes = useSelector(
    (state: RootState) => state.main.connectorTypes
  );
  const dispatch = useDispatch<AppDispatch>();
  const setConnectorTypes = (connectorTypes: ConnectorType[]) =>
    dispatch({ type: ActionType.SET_CONNECTOR_TYPES, connectorTypes });
  return [connectorTypes, setConnectorTypes];
};

export const useSelectedConnectorType: () => [
  any,
  (connectorTypes: any) => void
] = () => {
  const selectedConnectorType = useSelector(
    (state: RootState) => state.main.selectedConnectorType
  );
  const [selectedPage, setSelectedPage] = useSelectedPage();
  const dispatch = useDispatch<AppDispatch>();
  const setSelectedConnectorType = (selectedConnectorType: any) => {
    dispatch({
      type: ActionType.SET_SELECTED_CONNECTOR_TYPE,
      selectedConnectorType,
    });
    setSelectedPage({ contentType: PageContentType.ADD_CONNECT_CARD });
  };
  return [selectedConnectorType, setSelectedConnectorType];
};

export const useSelectedConnectCard: () => [
  ConnectCard | undefined,
  (selectedConnectCard: ConnectCard) => void
] = () => {
  const selectedConnectCard = useSelector(
    (state: RootState) => state.main.selectedConnectCard
  );
  const dispatch = useDispatch();
  const setSelectedConnectCard = (selectedConnectCard: ConnectCard) => {
    dispatch({
      type: ActionType.SET_SELECTED_CONNECT_CARD,
      selectedConnectCard,
    });
  };
  return [selectedConnectCard, setSelectedConnectCard];
};

export const useFetchSelectedConnectCard: () => any = () => {
  const [selectedConnectorType, setSelectedConnectorType] =
    useSelectedConnectorType();
  const [selectedConnectCard, setSelectedConnectCard] =
    useSelectedConnectCard();
  const selectedConnectorTypeId = selectedConnectorType?.id;
  const selectedConnectCardId = selectedConnectCard?.id;
  const [appId, setAppId] = useAppId();

  useEffect(() => {
    if (!selectedConnectCardId && selectedConnectorTypeId && appId) {
      createConnectCard({
        appId: appId!,
        schema: selectedConnectorTypeId,
      }).then((response) => {
        const card = response["connect-card"];
        setSelectedConnectCard(card);
      });
    }
  }, [selectedConnectCardId, selectedConnectorTypeId, appId]);
};
