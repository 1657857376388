import { UnknownAction, configureStore } from "@reduxjs/toolkit";
import { GetPaymentMethodsResponse, GetSubscriptionResponse } from "./api";
import { NoteEdits, Notebook, NotebookPage } from "./types/note";
import {
  ConnectCard,
  Connector,
  ConnectorStatusMap,
  ConnectorType,
} from "./types/pullConnector";
import {
  TableLogsMap,
  TableMap,
  TableNotFound,
  TableNotFoundMap,
  TablePagesMap,
  TableRunHistoryMap,
  TableRunResponses,
  TableSpecMap,
} from "./types/table";

const R = require("ramda");

export enum PageContentType {
  LOADING_ACCOUNT = "LOADING_ACCOUNT",
  NO_WORKSPACE = "NO_WORKSPACE",
  NOT_LOGGED_IN = "NOT_LOGGED_IN",
  PULL_CONNECTOR = "PULL_CONNECTOR",
  PULL_CONNECTOR_TABLE = "PULL_CONNECTOR_TABLE",
  SELECT_CONNECT_CARD_TYPE = "SELECT_CONNECT_CARD_TYPE",
  ADD_CONNECT_CARD = "ADD_CONNECT_CARD",
  NOTEBOOK = "NOTEBOOK",
  VIEW_PUSH_CONNECTOR = "VIEW_PUSH_CONNECTOR",
  EDIT_PUSH_CONNECTOR = "EDIT_PUSH_CONNECTOR",
  SETTINGS = "SETTINGS",
}

export interface SelectedPage {
  contentType: PageContentType;
  page?: any;
}

export enum MessageRole {
  ASSISTANT = "assistant",
  SYSTEM = "system",
  USER = "user",
}

export interface Message {
  role: MessageRole;
  content: string;
}

export type PushConnectorData = { [label: string]: string };

export interface PushConnector {
  id?: string;
  name: string;
  description: string;
  data: PushConnectorData;
}

export interface PushConnectorCreated extends PushConnector {
  id: string;
}

export type PushConnectorMap = { [id: string]: PushConnectorCreated };

export type PushConnectorFormField = { label: string; value: string };

export interface App {
  id: string;
  name: string;
  "owner-email": string;
}

export interface AppUser {
  "app-id": string;
  name: string;
  email: string;
}

export interface UserRole {
  role: string;
}

export interface PaymentMethod {
  "stripe-id": string;
  last4: "string";
  brand: string;
  "exp-month": string;
  "exp-year": string;
  created: number;
}

export interface StripeCustomer {
  "stripe-customer-id": string;
  email: string;
}

export interface MainState {
  tableRunHistoryMap: TableRunHistoryMap;
  notFoundTables: TableNotFoundMap;
  selectedPullConnector?: string;
  noteEdits: NoteEdits;
  tableLogsMap: TableLogsMap;
  tableRunResponses: TableRunResponses;
  connectorStatusMap: ConnectorStatusMap;
  appOwner?: string;
  stripeCustomer?: StripeCustomer;
  paymentMethods?: GetPaymentMethodsResponse;
  stripeSubscription?: GetSubscriptionResponse;
  roles: UserRole[];
  workspaceUsers: AppUser[];
  allUsers: AppUser[];
  initialPageLoaded?: boolean;
  notebook?: Notebook;
  selectedNote?: NotebookPage;
  allWorkspaces: App[];
  apps: App[];
  appId?: string;
  selectedPage: SelectedPage;
  chatId?: string;
  chatDrawerOpen: boolean;
  sideNavOpen: boolean;
  messages: Message[];
  connectors: Connector[];
  drawerWidth: string;
  tables: TableMap;
  connectorTables: any;
  chatLoading: boolean;
  connectorTypes: ConnectorType[];
  selectedConnectorType?: any;
  selectedConnectCard?: ConnectCard;
  authToken?: string;
  pushConnectors: PushConnectorMap;
  selectedPushConnector?: string;
  pushConnectorForm: PushConnectorFormField[];
  noWorkspaces?: boolean;
  tablePages: TablePagesMap;
  tableSpecs: TableSpecMap;
}

export enum ActionType {
  SET_TABLE_RUN_HISTORY_MAP = "SET_TABLE_RUN_HISTORY_MAP",
  ADD_NOT_FOUND_TABLES = "ADD_NOT_FOUND_TABLES",
  SET_SELECTED_PULL_CONNECTOR = "SET_SELECTED_PULL_CONNECTOR",
  SET_CONNECTOR_STATUS_MAP = "SET_CONNECTOR_STATUS_MAP",
  SET_NOTE_EDITS = "SET_NOTE_EDITS",
  SET_TABLE_LOGS_MAP = "SET_TABLE_LOGS_MAP",
  SET_TABLE_RUN_RESPONSES = "SET_TABLE_RUN_RESPONSES",
  SET_APP_OWNER = "SET_APP_OWNER",
  SET_STRIPE_SUBSCRIPTION = "SET_STRIPE_SUBSCRIPTION",
  SET_STRIPE_CUSTOMER = "SET_STRIPE_CUSTOMER",
  SET_PAYMENT_METHODS = "SET_PAYMENT_METHODS",
  SET_ALL_WORKSPACES = "SET_ALL_WORKSPACES",
  SET_ROLES = "SET_ROLES",
  SET_WORKSPACE_USERS = "SET_WORKSPACE_USERS",
  SET_ALL_USERS = "SET_ALL_USERS",
  SET_NOTEBOOK = "SET_NOTEBOOK",
  SET_APP_ID = "SET_APP_ID",
  SET_APPS = "SET_APPS",
  SET_SELECTED_PAGE = "SET_SELECTED_PAGE",
  SET_CHAT_ID = "SET_CHAT_ID",
  SET_SIDE_NAV_OPEN = "SET_SIDE_NAV_OPEN",
  SET_CHAT_DRAWER_OPEN = "SET_CHAT_DRAWER_OPEN",
  ADD_MESSAGE = "ADD_MESSAGE",
  SET_MESSAGES = "SET_MESSAGES",
  SET_CONNECTORS = "SET_CONNECTORS",
  ADD_TABLES = "ADD_TABLES",
  ASSOCIATE_CONNECTOR_TABLES = "ASSOCIATE_CONNECTOR_TABLES",
  SET_CHAT_LOADING = "SET_CHAT_LOADING",
  SET_CONNECTOR_TYPES = "SET_CONNECTOR_TYPES",
  SET_SELECTED_CONNECTOR_TYPE = "SET_SELECTED_CONNECTOR_TYPE",
  SET_CREATE_CONNECTOR_LOADING = "SET_CREATE_CONNECTOR_LOADING",
  SET_SELECTED_CONNECT_CARD = "SET_SELECTED_CONNECT_CARD",
  SET_AUTH_TOKEN = "SET_AUTH_TOKEN",
  SET_PUSH_CONNECTORS = "SET_PUSH_CONNECTORS",
  SET_SELECTED_PUSH_CONNECTOR = "SET_SELECTED_PUSH_CONNECTOR",
  SET_PUSH_CONNECTOR_FORM_DATA = "SET_PUSH_CONNECTOR_FORM_DATA",
  SET_INITIAL_PAGE_LOADING = "SET_INITIAL_PAGE_LOADING",
  SET_NO_WORKSPACES = "SET_NO_WORKSPACES",
  ADD_TABLE_PAGE = "ADD_TABLE_PAGE",
  SET_TABLE_SPECS = "SET_TABLE_SPECS",
  SET_SELECTED_NOTE = "SET_SELECTED_NOTE",
}

export interface Action extends UnknownAction {
  type: ActionType;
}

const initialState: MainState = {
  tableRunHistoryMap: {},
  notFoundTables: {},
  connectorStatusMap: {},
  noteEdits: {},
  tableLogsMap: {},
  tableRunResponses: {},
  roles: [],
  workspaceUsers: [],
  allUsers: [],
  apps: [],
  allWorkspaces: [],
  selectedPage: { contentType: PageContentType.LOADING_ACCOUNT },
  chatDrawerOpen: false,
  sideNavOpen: false,
  messages: [],
  connectors: [],
  drawerWidth: "15rem",
  tables: {},
  connectorTables: {},
  connectorTypes: [],
  chatLoading: false,
  tablePages: {},
  pushConnectors: {},
  pushConnectorForm: [
    { label: "name", value: "" },
    { label: "description", value: "" },
    { label: "prompt", value: "" },
  ],
  tableSpecs: {},
};

export type AddTablePageConfig = {
  tableId: string;
  index: number;
  rows: string[][];
};

export const reducer: (
  state: MainState | undefined,
  action: any
) => MainState = (state = initialState, action) => {
  switch (action.type as ActionType) {
    case ActionType.SET_TABLE_RUN_HISTORY_MAP:
      return { ...state, tableRunHistoryMap: action.tableRunHistoryMap };
    case ActionType.ADD_NOT_FOUND_TABLES:
      const notFoundTables = action.notFoundTables as TableNotFound[];
      const notFoundEntries = Object.fromEntries(
        notFoundTables.map((t) => [t["table-id"], t])
      );
      const newNotFoundTables = { ...state.notFoundTables, ...notFoundEntries };
      return { ...state, notFoundTables: newNotFoundTables };
    case ActionType.SET_SELECTED_PULL_CONNECTOR:
      return { ...state, selectedPullConnector: action.selectedPullConnector };
    case ActionType.SET_CONNECTOR_STATUS_MAP:
      return { ...state, connectorStatusMap: action.connectorStatusMap };
    case ActionType.SET_NOTE_EDITS:
      return { ...state, noteEdits: action.noteEdits };
    case ActionType.SET_TABLE_LOGS_MAP:
      return { ...state, tableLogsMap: action.tableLogsMap };
    case ActionType.SET_TABLE_RUN_RESPONSES:
      return { ...state, tableRunResponses: action.tableRunResponses };
    case ActionType.SET_APP_OWNER:
      return { ...state, appOwner: action.appOwner };
    case ActionType.SET_STRIPE_SUBSCRIPTION:
      return { ...state, stripeSubscription: action.stripeSubscription };
    case ActionType.SET_STRIPE_CUSTOMER:
      return { ...state, stripeCustomer: action.stripeCustomer };
    case ActionType.SET_PAYMENT_METHODS:
      return { ...state, paymentMethods: action.paymentMethods };
    case ActionType.SET_ROLES:
      return { ...state, roles: action.roles };
    case ActionType.SET_ALL_WORKSPACES:
      return { ...state, allWorkspaces: action.allWorkspaces };
    case ActionType.SET_WORKSPACE_USERS:
      return { ...state, workspaceUsers: action.workspaceUsers };
    case ActionType.SET_ALL_USERS:
      return { ...state, allUsers: action.allUsers };
    case ActionType.SET_NOTEBOOK:
      return { ...state, notebook: action.notebook };
    case ActionType.SET_APP_ID:
      return { ...state, appId: action.appId };
    case ActionType.SET_APPS:
      return { ...state, apps: action.apps };
    case ActionType.SET_SELECTED_PAGE:
      return { ...state, selectedPage: action.selectedPage };
    case ActionType.SET_CHAT_ID:
      return { ...state, chatId: action.chatId };
    case ActionType.SET_CHAT_DRAWER_OPEN:
      return { ...state, chatDrawerOpen: action.chatDrawerOpen };
    case ActionType.SET_SIDE_NAV_OPEN:
      return { ...state, sideNavOpen: action.sideNavOpen };
    case ActionType.ADD_MESSAGE:
      return { ...state, messages: [...state.messages, action.message] };
    case ActionType.SET_MESSAGES:
      return { ...state, messages: action.messages };
    case ActionType.SET_CONNECTORS:
      return { ...state, connectors: action.connectors };
    case ActionType.ADD_TABLES:
      const tableEntries = R.map(
        (table: any) => [table["table-id"], table],
        action.tables
      );
      const addTableObject = Object.fromEntries(tableEntries);
      const newTables = { ...state.tables, ...addTableObject };
      return { ...state, tables: newTables };
    case ActionType.ADD_TABLE_PAGE:
      const actionAddTablePage = action as AddTablePageConfig;
      const { tableId, index, rows } = actionAddTablePage;
      const currentTablePagesEntry = state.tablePages[tableId] ?? {};
      const newTablePagesEntry = {
        ...currentTablePagesEntry,
        [index]: rows,
      };
      const newTablePages = {
        ...state.tablePages,
        [tableId]: newTablePagesEntry,
      };
      return { ...state, tablePages: newTablePages };
    case ActionType.ASSOCIATE_CONNECTOR_TABLES:
      const newConnectorTables = {
        ...state.connectorTables,
        ...action.connectorTables,
      };
      return { ...state, connectorTables: newConnectorTables };
    case ActionType.SET_CHAT_LOADING:
      return { ...state, chatLoading: action.chatLoading };
    case ActionType.SET_CONNECTOR_TYPES:
      return { ...state, connectorTypes: action.connectorTypes };
    case ActionType.SET_SELECTED_CONNECT_CARD:
      return { ...state, selectedConnectCard: action.selectedConnectCard };
    case ActionType.SET_SELECTED_CONNECTOR_TYPE:
      return { ...state, selectedConnectorType: action.selectedConnectorType };
    case ActionType.SET_AUTH_TOKEN:
      return { ...state, authToken: action.authToken };
    case ActionType.SET_PUSH_CONNECTORS:
      return { ...state, pushConnectors: action.pushConnectors };
    case ActionType.SET_SELECTED_PUSH_CONNECTOR:
      return {
        ...state,
        selectedPushConnector: action.selectedPushConnector,
        selectedPage: { contentType: PageContentType.VIEW_PUSH_CONNECTOR },
      };
    case ActionType.SET_PUSH_CONNECTOR_FORM_DATA:
      return {
        ...state,
        pushConnectorForm: action.pushConnectorForm,
      };
    case ActionType.SET_INITIAL_PAGE_LOADING:
      return { ...state, initialPageLoaded: action.initialPageLoaded };
    case ActionType.SET_NO_WORKSPACES:
      return action.noWorkspaces
        ? {
            ...state,
            noWorkspaces: action.noWorkspaces,
            selectedPage: { contentType: PageContentType.NO_WORKSPACE },
          }
        : { ...state, noWorkspaces: action.noWorkspaces };
    case ActionType.SET_TABLE_SPECS:
      return { ...state, tableSpecs: action.tableSpecs };
    case ActionType.SET_SELECTED_NOTE:
      return {
        ...state,
        selectedNote: action.selectedNote,
        selectedPage: { contentType: PageContentType.NOTEBOOK },
      };
  }
  return state;
};

export const store = configureStore({ reducer: { main: reducer } });

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
export default store;
