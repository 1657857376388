import {
  Alert,
  Box,
  Button,
  IconButton,
  Snackbar,
  TextField,
  Tooltip,
} from "@mui/material";
import {
  useAddPushConnectorFormField,
  useDeleteSelectedPushConnector,
  useRemovePushConnectorFormField,
  useSetPushConnectorFormField,
  useSubmitPushConnectorCreateForm,
  useSubmitPushConnectorEditForm,
} from "../../../hook/pushConnector/edit";
import { PushConnectorFormField } from "../../../reducer";

import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import { useState } from "react";

export const PushConnectorFormFieldComponent: React.FC<{
  field: PushConnectorFormField;
  disabled?: boolean;
  index: number;
}> = ({ field: { label, value }, disabled = false, index }) => {
  const labelDisabled =
    disabled ||
    label === "description" ||
    label === "name" ||
    label === "prompt";
  const setPushConnectorFormField = useSetPushConnectorFormField();
  const removePushConnectorFormField = useRemovePushConnectorFormField();
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");

  const handleCopy = async (textToCopy: string, type: "label" | "value") => {
    try {
      await navigator.clipboard.writeText(textToCopy);
      console.log(`${type} copied to clipboard:`, textToCopy);
      setSnackbarMessage(
        `${type.charAt(0).toUpperCase() + type.slice(1)} copied!`,
      );
      setSnackbarOpen(true);
    } catch (err) {
      console.error(`Failed to copy ${type}: `, err);
      setSnackbarMessage(`Failed to copy ${type}.`);
      setSnackbarOpen(true);
    }
  };

  const handleCopyLabel = () => {
    handleCopy(label, "label");
  };

  const handleCopyValue = () => {
    handleCopy(value, "value");
  };

  const handleSnackbarClose = (
    event?: React.SyntheticEvent | Event,
    reason?: string,
  ) => {
    if (reason === "clickaway") {
      return;
    }
    setSnackbarOpen(false);
  };

  return (
    <>
      <Box sx={{ display: "flex", gap: 3, alignItems: "flex-start" }}>
        <Box
          sx={{
            display: "flex",
            flexGrow: 1,
            alignItems: "center",
            gap: 1,
          }}
        >
          <TextField
            sx={{ flexGrow: 1 }}
            label={"label"}
            value={label}
            disabled={labelDisabled}
            onChange={(e) => {
              setPushConnectorFormField({
                field: { label: e.target.value, value },
                index,
              });
            }}
          />
          <Tooltip title="Copy label">
            <IconButton size="small" onClick={handleCopyLabel}>
              <ContentCopyIcon fontSize="small" color="primary" />
            </IconButton>
          </Tooltip>
        </Box>

        <Box
          sx={{
            display: "flex",
            flexGrow: 1,
            alignItems: "center",
            gap: 1,
          }}
        >
          <TextField
            sx={{ flexGrow: 1 }}
            label={"value"}
            value={value}
            disabled={disabled}
            multiline={true}
            onChange={(e) => {
              setPushConnectorFormField({
                field: { label, value: e.target.value },
                index,
              });
            }}
          />
          <Tooltip title="Copy value">
            <IconButton size="small" onClick={handleCopyValue}>
              <ContentCopyIcon fontSize="small" color="primary" />
            </IconButton>
          </Tooltip>
        </Box>

        <Box sx={{ pt: 1 }}>
          {!labelDisabled && (
            <IconButton
              onClick={() => {
                removePushConnectorFormField(index);
              }}
            >
              <DeleteIcon color="primary" />
            </IconButton>
          )}
        </Box>
      </Box>
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={3000}
        onClose={handleSnackbarClose}
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
      >
        <Alert
          onClose={handleSnackbarClose}
          severity={snackbarMessage.startsWith("Failed") ? "error" : "success"}
          sx={{ width: "100%" }}
        >
          {snackbarMessage}
        </Alert>
      </Snackbar>
    </>
  );
};

export const PushConnectorFormFields: React.FC<{
  pushConnectorForm: PushConnectorFormField[];
  disabled?: boolean;
}> = ({ pushConnectorForm, disabled: disabledProp = false }) => {
  const addPushConnectorFormField = useAddPushConnectorFormField();
  const submitPushConnectorCreateForm = useSubmitPushConnectorCreateForm();
  const submitPushConnectorEditForm = useSubmitPushConnectorEditForm();
  const deleteSelectedPushConnector = useDeleteSelectedPushConnector();
  const [editMode, setEditMode] = useState(false);
  const disabled = disabledProp && !editMode;
  const submitForm = editMode
    ? submitPushConnectorEditForm
    : submitPushConnectorCreateForm;

  return (
    <Box sx={{ display: "flex", flexDirection: "column", gap: 6 }}>
      {pushConnectorForm.map((field, index) => (
        <PushConnectorFormFieldComponent
          field={field}
          key={index}
          index={index}
          disabled={disabled}
        />
      ))}
      <Box sx={{ display: "flex", justifyContent: "end" }}>
        {!disabled && (
          <>
            <Button onClick={addPushConnectorFormField} color="primary">
              Add Field
            </Button>
            <Button onClick={submitForm} color="primary">
              Save
            </Button>
          </>
        )}
        {disabled && (
          <>
            <IconButton onClick={() => setEditMode(true)} color="primary">
              <EditIcon />
            </IconButton>
            <IconButton onClick={deleteSelectedPushConnector} color="primary">
              <DeleteIcon />
            </IconButton>
          </>
        )}
      </Box>
    </Box>
  );
};
